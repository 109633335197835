import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons'; // Importer l'icône hamburger
import { SettingsIcon } from '@chakra-ui/icons';  
import { AiOutlineHeart, AiFillHeart, AiOutlineCheckCircle } from 'react-icons/ai';
import { Tooltip } from '@chakra-ui/react';
import Header from '../components/HomePage/Header'; // Import du composant Header
import CategoryBoxes from '../components/HomePage/CategoryBoxes'; // Import du composant Header
import StoryList from '../components/HomePage/StoryList';
import Carousel from '../components/HomePage/Carousel';



import { 
  Box, 
  VStack, 
  Image, 
  Text, 
  Button, 
  Heading, 
  Flex, 
  HStack, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody, 
  ModalFooter, 
  Circle, 
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useBreakpointValue, // Import de useBreakpointValue
  useToast // Import du toast
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick'; // Importer le slider
import '../styles/HomePage.css';
import IndependentBlock from '../components/IndependentBlock';


const categories = ['Bestseller', 'Spécial', 'Nouveautés', 'En création', 'Prochainement'];

const carouselSettings = {
  dots: true, // Affiche les petits points de navigation en bas
  infinite: true,         // Permet le défilement infini
  speed: 500, // Vitesse de transition entre les slides
  slidesToShow: 1,  // Vous pouvez ajuster ici
  slidesToScroll: 1, // Fait défiler une image à la fois
  autoplay: true,         // Active le défilement automatique
  autoplaySpeed: 3000, // Vitesse du défilement automatique (3s)
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,   // 2 images sur les écrans de taille moyenne
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,   // 1 image sur les petits écrans
        slidesToScroll: 1,
      },
    },
  ],
};
  
const MotionBox = motion(Box);



function HomePage() {
  const [boxes, setBoxes] = useState([]);
  const [username, setUsername] = useState(null);
  const [userRole, setUserRole] = useState(null); // État pour stocker le rôle de l'utilisateur (modèle ou non)
  const [modelId, setModelId] = useState(null); // État pour stocker l'ID du modèle
  const [userId, setUserId] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCasesOpened, setTotalCasesOpened] = useState(0);
  const [stories, setStories] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialOpen, setInitialOpen] = useState(true);
  const maxVisibleStories = useBreakpointValue({ base: 3, md: 5, lg: 7 }); // Nombre de stories visibles basé sur la taille de l'écran (3 pour mobile, 5 pour tablette, 7 pour desktop)
  const [userKeysBalance, setUserKeysBalance] = useState(null); // État pour stocker les clés
  const [likes, setLikes] = useState({}); // État pour stocker les likes des caisses
  const [searchQuery, setSearchQuery] = useState(''); // Nouvel état pour la recherche
  const [filteredBoxes, setFilteredBoxes] = useState([]); // État pour les boîtes filtrées
  const [lastOpenings, setLastOpenings] = useState([]);
  const [userOpenings, setUserOpenings] = useState([]);
  const [suggestions, setSuggestions] = useState([]); // Suggestions récupérées

  const apiUrl = process.env.REACT_APP_API_URL || 'https://onlybox.fans/api';

  // Charger les suggestions depuis l'API
  useEffect(() => {
    const fetchSuggestions = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/suggestions/home?userId=${userId}`);
            const data = await response.json();
            console.log("Données des suggestions récupérées :", data); // Ajoute ce log
            setSuggestions(data); // Stocker les suggestions récupérées
        } catch (error) {
            console.error("Erreur lors de la récupération des suggestions :", error);
        }
    };

    fetchSuggestions();
}, [apiUrl, userId]);


  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      console.warn('Aucun userId trouvé dans le localStorage.');
    }
  }, []);
  
  useEffect(() => {
    const fetchOpenings = async () => {
      try {
        // Fetch pour les ouvertures globales
        const lastOpeningsResponse = await fetch(`${apiUrl}/api/purchase/last-openings`);
        const lastOpeningsData = await lastOpeningsResponse.json();
        if (lastOpeningsData.success) {
          setLastOpenings(lastOpeningsData.lastOpenings);
        } else {
          console.error(lastOpeningsData.message);
        }
  
        // Fetch pour les ouvertures utilisateur
        if (userId) {
          const userOpeningsResponse = await fetch(`${apiUrl}/api/purchase/user-openings/${userId}`);
          const userOpeningsData = await userOpeningsResponse.json();
          if (userOpeningsData.success) {
            setUserOpenings(userOpeningsData.userOpenings);
          } else {
            console.error(userOpeningsData.message);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
  
    fetchOpenings(); // Appel de la fonction asynchrone
  }, [userId]); // Dépendance à `userId`

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    const filtered = boxes.filter((box) => 
      box.title.toLowerCase().includes(query.toLowerCase()) || 
      box.description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredBoxes(filtered);
  };
  
  useEffect(() => {
    setFilteredBoxes(boxes); // Initialiser les boîtes filtrées avec toutes les boîtes au chargement
  }, [boxes]);

  const navigate = useNavigate();
  const toast = useToast(); // Initialisation du toast

  const toggleLike = async (boxId) => {
    const apiUrl = process.env.REACT_APP_API_URL; // Utiliser la variable d'environnement pour l'API
  
    try {
      // Inverser l'état local du like
      const isLiked = !likes[boxId];
      setLikes((prevLikes) => ({
          ...prevLikes,
          [boxId]: isLiked,
      }));

      // Déterminer l'URL de l'API pour liker ou unliker
      const endpoint = isLiked ? '/api/box/like-box' : '/api/box/unlike-box';

      // Effectuer une requête POST pour envoyer le like/unlike au backend
      const response = await fetch(`${apiUrl}${endpoint}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              boxId,
              userId,  // Utiliser l'ID de l'utilisateur connecté
          }),
      });

      const data = await response.json();

      if (response.ok) {
          console.log(`Nombre de likes mis à jour: ${data.likes}`)

          // Afficher un toast pour confirmer l'action
        toast({
          title: "💖 Merci pour votre like !",
          description: "Elle vous sera reconnaissante !",
          status: "success",
          duration: 8000, // Le toast restera affiché pendant 8 secondes
          isClosable: true,
          position: "top",
        });

        // Rafraîchir la page pour mettre à jour l'interface utilisateur
        setTimeout(() => {
          window.location.reload(); // Rafraîchit la page après un délai pour permettre au toast de s'afficher
        }, 1000); // Attends 1 seconde avant de rafraîchir la page pour laisser le toast s'afficher
    } else {
        console.error(`Erreur lors de la mise à jour du like: ${data.message}`);
    }
  } catch (error) {
      console.error('Erreur lors du like/unlike:', error);
  }
};

// Fetch des images du carrousel
useEffect(() => {
  const fetchCarouselImages = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/carousel`);
      const data = await response.json();
      console.log(data); // Afficher les données pour vérifier leur structure
      setCarouselImages(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des images du carrousel:', error);
    }
  };
  fetchCarouselImages();
}, []);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL; // Utiliser la variable d'environnement pour l'API

    const fetchKeysBalance = async (userId) => {
      try {
        const response = await fetch(`${apiUrl}/api/user/keys-balance/${userId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setUserKeysBalance(data.keys_balance); // Mise à jour du solde des clés
      } catch (error) {
        console.error('Erreur lors de la récupération du solde des clés :', error);
      }
    };
  
    // Si l'utilisateur est connecté, récupérer son solde de clés
    if (userId) {
      fetchKeysBalance(userId);
    }

    const fetchBoxes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/boxes`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
    
        const data = await response.json();
    
        if (!userId) {
          setBoxes(data);
          setFilteredBoxes(data); // Mettez à jour les boîtes filtrées
          return; // Si l'utilisateur n'est pas connecté, on s'arrête ici
        }
    
        // Récupérer les informations de l'utilisateur
        const userResponse = await fetch(`${apiUrl}/api/user/${userId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
    
        const userData = await userResponse.json();
    
        // Vérifier si l'utilisateur a liké chaque box et mettre à jour l'état des likes
        const likedBoxes = userData.likedBoxes || [];
        const updatedLikes = {};
        likedBoxes.forEach((boxId) => {
          updatedLikes[boxId] = true; // Les boîtes likées par cet utilisateur
        });
    
        setLikes(updatedLikes);
    
        // Vérifiez si l'utilisateur a ouvert chaque boîte
        const updatedBoxes = data.map((box) => ({
          ...box,
          alreadyOpened: box.openedByUsers.includes(userId), // Vérifie si l'utilisateur est dans `openedByUsers`
        }));
    
        // Mettre à jour les boîtes et les boîtes filtrées
        setBoxes(updatedBoxes);
        setFilteredBoxes(updatedBoxes);
      } catch (error) {
        console.error('Erreur lors de la récupération des boîtes :', error);
      }
    };

    const fetchStories = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin/stories`);
        const data = await response.json();
        setStories(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des stories:', error);
      }
    };

    const fetchStatistics = async () => {
      try {
        const totalUsersResponse = await fetch(`${apiUrl}/api/total-users`);
        const totalUsersData = await totalUsersResponse.json();
        setTotalUsers(totalUsersData.totalUsers);

        const totalCasesOpenedResponse = await fetch(`${apiUrl}/api/total-cases-opened`);
        const totalCasesOpenedData = await totalCasesOpenedResponse.json();
        setTotalCasesOpened(totalCasesOpenedData.totalCasesOpened);
      } catch (error) {
        console.error('Erreur lors de la récupération des statistiques:', error);
      }
    };

    fetchBoxes();
    fetchStories();
    fetchStatistics();

    const storedUsername = localStorage.getItem('username');
    const storedRole = localStorage.getItem('role');
    const storedModelId = localStorage.getItem('modelId');
    const storedUserId = localStorage.getItem('userId');

    console.log('Nom utilisateur:', storedUsername);
    console.log('Rôle utilisateur:', storedRole);
    console.log('ID du modèle:', storedModelId);
    console.log('ID de l\'utilisateur:', storedUserId); // Vérification de l'ID utilisateur

    setUsername(storedUsername);
    setUserRole(storedRole);
    setModelId(storedModelId);
    setUserId(storedUserId);
  }, [userId]);

  // Fonction pour gérer la déconnexion
  const handleLogout = () => {
    localStorage.clear();
    setUsername(null);
    setUserRole(null);
    setUserId(null);
    setModelId(null);

    // Afficher le toast de déconnexion
    toast({
      title: "👋 Vous nous quittez déjà ?",
      description: "À très bientôt pour de nouvelles surprises !",
      status: "info",
      duration: 3000,
      isClosable: true,
      position: "top"
    });
    
    navigate('/');
  };

  const toggleMenu = () => setShowMenu((prev) => !prev);

  const handleStoryClick = (story) => {
    setSelectedStory(story);
    setIsModalOpen(true);
    setInitialOpen(true);
  };

  const closeModal = () => {
    setSelectedStory(null);
    setIsModalOpen(false);
  };

  const showNextStory = () => {
    const currentIndex = stories.findIndex((s) => s._id === selectedStory._id);
    const nextIndex = (currentIndex + 1) % stories.length;
    setSelectedStory(stories[nextIndex]);
    setInitialOpen(false);
  };

  const showPreviousStory = () => {
    const currentIndex = stories.findIndex((s) => s._id === selectedStory._id);
    const previousIndex = (currentIndex - 1 + stories.length) % stories.length;
    setSelectedStory(stories[previousIndex]);
    setInitialOpen(false);
  };



  return (
    <Box>
      <Box className="background-image"></Box>
      <Box className="content" padding="20px">
        {/* Utilisation du sous-composant Header.js */}
        <Header
          totalUsers={totalUsers}
          totalCasesOpened={totalCasesOpened}
          username={username}
          userKeysBalance={userKeysBalance}
          userRole={userRole}
          modelId={modelId}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
          navigate={navigate}
          handleLogout={handleLogout}
        />
  
        {/* Ajout de la barre de recherche */}
        <Box className="search-bar-container" marginBottom={5}>
          <input
            type="text"
            placeholder="Taper ici pour rechercher"
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-bar-input"
          />
        </Box>
  
        <Carousel
          carouselImages={carouselImages}
          carouselSettings={carouselSettings}
          apiUrl={apiUrl}
        />
  
        {/* Section Ouvertures en direct */}
        <Box className="direct-openings-section" mb={5}>
          <IndependentBlock
            lastOpenings={lastOpenings}
            userOpenings={[]} // Liste vide pour "Ouvertures en direct"
          />
        </Box>
  
        {/* Section Vos dernières ouvertures */}
        <Box className="user-openings-section" mb={5}>
          <IndependentBlock
            lastOpenings={[]} // Liste vide pour "Vos dernières ouvertures"
            userOpenings={userOpenings}
          />
        </Box>
  
        <VStack spacing={5} mt={5}>
          <Heading
            sx={{
              background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              transition: 'transform 0.2s',
              cursor: 'pointer',
              _hover: { transform: 'scale(1.1)' },
            }}
            onClick={() => window.location.reload()}
          >
            Onlybox
          </Heading>
  
          <HStack>
            <StoryList
              stories={stories}
              maxVisibleStories={maxVisibleStories}
              handleStoryClick={handleStoryClick}
              selectedStory={selectedStory}
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              showNextStory={showNextStory}
              showPreviousStory={showPreviousStory}
              apiUrl={apiUrl}
              initialOpen={initialOpen}
            />
          </HStack>
  
          {/* Passer les données comme props à CategoryBoxes.js */}
          <CategoryBoxes
            categories={categories}
            filteredBoxes={filteredBoxes}
            apiUrl={apiUrl}
            likes={likes}
            toggleLike={toggleLike}
            suggestions={suggestions} // Ajout de la prop suggestions
          />
        </VStack>
      </Box>
    </Box>
  );
}
  
  export default HomePage;
