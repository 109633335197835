import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/BoxDetailPage.css'; // Utilisation exclusive du fichier CSS
import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa'; // Ajout de FaTimesCircle
import Modal from 'react-modal';
import { Box, Image, Text, Button, VStack, HStack, Badge, Spinner, useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import Particles from "../components/Particles";
import axios from '../utils/axios';
import { fetchBoxDetails, openBox, registerPurchase } from '../api/boxApi';
import BoxHeader from '../components/BoxHeader';
import BoxMediaPreview from '../components/BoxMediaPreview';
import ResultOverlay from '../components/ResultOverlay';
import RollAnimation from '../components/RollAnimation';




const preloadMedia = async (files, apiUrl) => {
  const promises = files.slice(0, 10).map((file) => {
    if (file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')) {
      return new Promise((resolve) => {
        const video = document.createElement('video');
        video.src = `${apiUrl}/uploads/animations/${file.filename}`;
        video.onloadeddata = resolve;
        video.onerror = resolve; // Éviter un blocage en cas d'erreur
      });
    }
    return Promise.resolve(); // Pas une vidéo, ignorer
  });
  await Promise.all(promises);
};

Modal.setAppElement('#root');

function BoxDetailPage() {
  const { id } = useParams();
  const [box, setBox] = useState({});
  const [result, setResult] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const [rollImages, setRollImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [backgroundBlurred, setBackgroundBlurred] = useState(false);
  const [closingOverlay, setClosingOverlay] = useState(false); // État temporaire pour empêcher une réouverture
  const [showFullDescription, setShowFullDescription] = useState(false); // Nouvel état pour "Voir plus"
  const [loading, setLoading] = useState(true); // Ajout de l'état de chargement
  const [userKeysBalance, setUserKeysBalance] = useState(0); // État pour stocker le solde des clés
  const [isRollLocked, setIsRollLocked] = useState(false);
  const [resetAnimationState, setResetAnimationState] = useState(false); // Ajout de `resetAnimationState`
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // État pour désactiver le bouton
  const rollTrackRef = useRef(null);
  const toast = useToast();
  const audioRef = useRef(null);
  const [mediaPreloaded, setMediaPreloaded] = useState(false);
  const buttonRef = useRef(null); // Référence pour récupérer la position du bouton
  const [buttonPosition, setButtonPosition] = useState(null); // Position dynamique du bouton



  // Détection si l'utilisateur est sur mobile
  // Cette constante sera utilisée pour désactiver la lecture automatique des vidéos sur les appareils mobiles
  /*const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);*/


  const apiUrl = process.env.REACT_APP_API_URL; // Utilisation de la variable d'environnement
  const userId = localStorage.getItem('userId'); // Récupérer l'ID utilisateur

  const checkServerConnection = useCallback(async () => {
    try {
      await axios.get(`${apiUrl}/api/ping`);
      return true; // Connexion au serveur active
    } catch (error) {
      return false; // Pas de connexion au serveur
    }
  }, [apiUrl]); // Dépendance sur apiUrl


  const reloadBoxContent = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/boxes/${id}/view`);
      const updatedBox = await response.json();
      setBox(updatedBox); // Met à jour les détails de la box
  
      const newRollImages = updatedBox.mediaFiles.map(file => {
        return file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')
          ? `${apiUrl}/uploads/animations/${file.filename}`
          : `${apiUrl}/uploads/floutees/${file.filename}`;
      });
      setRollImages(newRollImages); // Met à jour les images pour le rouleau

      // Précharge les vidéos après rechargement des détails
    if (updatedBox.mediaFiles) {
      await preloadMedia(updatedBox.mediaFiles, apiUrl);
      console.log("Vidéos rechargées et préchargées");
    }

    } catch (error) {
      console.error('Erreur lors du rechargement du contenu de la box :', error);
    }
  };


  // Fonction pour réinitialiser tous les états avec un délai
  const resetAllStates = () => {
    setRollImages([]);
    setResult(null);
    setBackgroundBlurred(false);
    setIsRolling(false); // S'assure que l'animation est arrêtée

    /*// Nettoyer les vidéos chargées dans l'animation
  const videos = document.querySelectorAll('.roll-media');
  videos.forEach(video => {
    video.pause();
    //video.removeAttribute('src'); // Retire la source pour libérer la mémoire
    video.load();
  });*/

  // Réinitialiser les états des photos si nécessaire
  const images = document.querySelectorAll('.roll-image');
  images.forEach(img => {
    img.src = ''; // Efface temporairement la source pour forcer un rechargement
  });

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    // Stoppe et retire la source de toutes les vidéos pour éviter une relecture
    const videoElements = document.querySelectorAll('video');
    if (videoElements.length > 0) {
      videoElements.forEach(video => {
      video.pause();
      video.removeAttribute('src');
      video.load();
    });
    }
  };

  // Fonction pour fermer l'image gagnante avec un délai et éviter la réouverture
  const handleCloseImage = () => {
    setClosingOverlay(true); // Activer l'état temporaire pour éviter la réouverture
    setIsButtonDisabled(true); // Désactive le bouton immédiatement

    // Utiliser un délai pour s'assurer que tout est bien réinitialisé
    setTimeout(() => {
      resetAllStates();
      setIsModalOpen(false);
      setClosingOverlay(false); // Désactiver l'état temporaire après la fermeture
      setResetAnimationState(false); // Fin du cycle de réinitialisation de l'animation
    
      // Vérification pour les caisses mixtes et le type de média gagné
      const isMixedBox =
      box?.mediaFiles?.some((file) => file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')) &&
      box?.mediaFiles?.some((file) => !file.filename.endsWith('.mp4') && !file.filename.endsWith('.webm') && !file.filename.endsWith('.mov'));
    
      const isVideo = result && (result.endsWith('.mp4') || result.endsWith('.webm') || result.endsWith('.mov'));
      const isPhoto = result && !isVideo;
    
      if (isMixedBox && isPhoto) {
        // Rafraîchir complètement la page pour les caisses mixtes avec des photos gagnées
        window.location.reload(); // Désactivé
      } else if (isVideo) {
        // Actualisation de la page si une vidéo est gagnée
        window.location.reload(); // Désactivé
      } else {
        // Recharge uniquement les contenus pour d'autres cas
        reloadBoxContent();
      }
    
      // Réactiver le bouton après une courte période
      const delay = isVideo ? 9000 : 6000; // 8 secondes pour vidéos, 5 secondes pour photos
      setTimeout(() => {
        setIsButtonDisabled(false); // Réactive le bouton sans actualisation de la page
      }, delay);
    }, 800); // Délai pour permettre une réinitialisation complète
  };


  
  // Effet pour quitter le plein écran si `result` est remis à `null`
useEffect(() => {
  if (result === null && document.fullscreenElement) {
    try {
      document.exitFullscreen();
    } catch (error) {
      console.error("Erreur lors de la sortie du mode plein écran :", error);
    }
  }
}, [result]);

   // Utilisation de l'effet pour relancer l'animation après la fermeture de l'overlay
  useEffect(() => {
    if (resetAnimationState) {
      setRollImages((prevImages) => [...prevImages]); // Force la réinitialisation des vidéos
      setIsRolling(true); // Redémarre l'animation
      setResetAnimationState(false); // Empêche la réinitialisation continue
    }
  }, [resetAnimationState]);

  // Charger les images d'animation avec une clé unique pour forcer le rechargement
useEffect(() => {
  const fetchRollImages = async () => {
    if (box.mediaFiles) {
      const newRollImages = box.mediaFiles.map((file, index) => {
        const src = file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')
          ? `${apiUrl}/uploads/animations/${file.filename}`
          : `${apiUrl}/uploads/floutees/${file.filename}`;
        return { src, key: `${file.filename}-${index}-${Date.now()}` }; // Clé unique pour chaque image
      });
      setRollImages(newRollImages);
    }
  };
  fetchRollImages();
}, [box, apiUrl]); // Ajoutez `box` et `apiUrl` comme dépendances

// Nettoie toute vidéo en plein écran lorsque `result` est remis à `null` pour éviter les pop-ups fantômes
useEffect(() => {
  if (result === null && document.fullscreenElement) {
    try {
      document.exitFullscreen();
    } catch (error) {
      console.error("Erreur lors de la sortie du mode plein écran :", error);
    }
  }
}, [result]);


  // Code pour désactiver le clic droit sur toute la page
useEffect(() => {
  const disableRightClick = (event) => {
    event.preventDefault();
  };

  document.addEventListener('contextmenu', disableRightClick);

  // Nettoyage pour supprimer l'écouteur quand le composant est démonté
  return () => {
    document.removeEventListener('contextmenu', disableRightClick);
  };
}, []);
  

// 1. Déclaration de la fonction `fetchKeysBalance` pour récupérer le solde des clés
const fetchKeysBalance = useCallback(async () => {
  try {
    const response = await axios.get(`/api/user/keys-balance/${userId}`);
    setUserKeysBalance(response.data.keys_balance); // Mise à jour du solde des clés
  } catch (error) {
    console.error('Erreur lors de la récupération du solde des clés :', error);
  }
}, [userId]);


// Fonction pour enregistrer l'achat dans MongoDB fàit
const handleRegisterPurchase = async (userId, boxId, boxPrice, countryCode) => {
  try {
    const purchaseData = {
      userId, // Assurez-vous que `userId` est défini dans votre composant
      boxId,
      amountTTC: boxPrice,
      countryCode,
    };

    console.log("Données envoyées pour l'achat :", purchaseData);

    // Utiliser la fonction `registerPurchase` depuis boxApi.js
    const response = await registerPurchase(apiUrl, purchaseData);

    console.log("Achat enregistré avec succès :", response);
  } catch (error) {
    console.error("Erreur lors de l'enregistrement de l'achat :", error);
  }
};

// 2. Fonction pour ouvrir une caisse et déduire les clés  fàit
const handleOpenBox = async (boxId, boxPrice) => {
  if (closingOverlay) return; // Empêche l'ouverture si on est en train de fermer l'overlay

  // Vérifiez la connexion au serveur avant de continuer
  const isConnected = await checkServerConnection();
  if (!isConnected) {
    toast({
      title: "Erreur de connexion",
      description: "Vous devez être connecté à Internet pour ouvrir une caisse.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
    return; // Arrêter l'exécution
  }

  resetAllStates(); // Réinitialise les états avant de commencer

  try {
    // Utiliser la fonction openBox depuis boxApi.js
    const data = await openBox(apiUrl, userId, boxId, boxPrice);
    
    
    toast({
      title: "Caisse ouverte avec succès!",
      description: "Félicitations, vous avez ouvert une caisse !",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });

    
    // Mettre à jour le solde des clés
    if (data.keys_balance !== undefined) {
      setUserKeysBalance(data.keys_balance); // Mise à jour du solde des clés
    } else {
      fetchKeysBalance(); // Rafraîchir le solde des clés si non retourné
    }

    // Ajout du console.log pour le débogage
    console.log("Données retournées par l'API:", data);
      
    // Ajout du console.log pour déboguer les données
    console.log("Données envoyées pour l'achat :", {
      userId,
      boxId,
      amountTTC: boxPrice,
      countryCode: box.countryCode,
    });

    // Appel de la fonction pour enregistrer l'achat dans MongoDB
    await handleRegisterPurchase(userId, boxId, boxPrice, box.countryCode); // Nouvelle fonction ici

     // 3. Appel de `fetchKeysBalance` pour rafraîchir le solde après ouverture de la caisse
     fetchKeysBalance(); 

    console.log("Données retournées par l'API :", data);
  } catch (error) {
    console.error('Erreur lors de l\'ouverture de la caisse:', error);
    toast({
      title: "Erreur serveur",
      description: "Impossible d'ouvrir la caisse pour le moment.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }
};


// Désactiver le bouton si l'utilisateur n'a pas assez de clés ou si la box n'a pas de prix
const isDisabled = userKeysBalance < (box ? box.price : 0);


// Désactiver le bouton pendant 5 secondes après le chargement de la page
useEffect(() => {
  setIsButtonDisabled(true);
  const delayTimeout = setTimeout(() => {
    setIsButtonDisabled(false); // Réactiver le bouton après 5 secondes
  }, 3000);

  return () => clearTimeout(delayTimeout); // Nettoyage
}, []);

useEffect(() => {
  const fetchBox = async () => {
    try {
      // Utiliser la fonction de boxApi.js
      const data = await fetchBoxDetails(apiUrl, id); 
      console.log("Détails de la box :", data); // Vérifie si countryCode est présent
      setBox(data); // Met à jour les détails de la box

      // Séparer les fichiers en photos et vidéos
      const photos = data.mediaFiles.filter(
        (file) => !file.filename.endsWith('.mp4') && !file.filename.endsWith('.webm') && !file.filename.endsWith('.mov')
      );
      const videos = data.mediaFiles.filter(
        (file) => file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')
      );

      console.log("Photos :", photos);
      console.log("Vidéos :", videos);

      // Préchargement des vidéos après récupération des détails
      if (data.mediaFiles) {
        await preloadMedia(data.mediaFiles, apiUrl); // Précharge les médias
        console.log("Préchargement des vidéos terminé");
      }

      // Initialiser rollImages après récupération des données
      const newRollImages = data.mediaFiles.map(file => {
        return file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')
          ? `${apiUrl}/uploads/animations/${file.filename}`
          : `${apiUrl}/uploads/floutees/${file.filename}`;
      });
      setRollImages(newRollImages); // Met à jour rollImages avec les chemins appropriés

    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la caisse :', error);
    } finally {
      setLoading(false);
    }
  };

  fetchBox();

  // Appel de `fetchKeysBalance` pour récupérer les clés initialement
  if (userId) {
    fetchKeysBalance();
  }
}, [id, userId, apiUrl, fetchKeysBalance]); // Assurez-vous que le tableau de dépendances est complet

// Préchargement des vidéos après chargement des médias
useEffect(() => {
  const preloadBoxMedia = async () => {
    if (box.mediaFiles && box.mediaFiles.length > 0) {
      console.log("Préchargement des vidéos en cours...");
      await preloadMedia(box.mediaFiles, apiUrl); // Appel de preloadMedia ici
      console.log("Préchargement terminé.");
    }
  };

  preloadBoxMedia();
}, [box, apiUrl]); // Dépendances


// Surveillance de la connexion au serveur
useEffect(() => {
  let interval;

  const monitorConnection = async () => {
    const isConnected = await checkServerConnection();
    setIsButtonDisabled(!isConnected); // Désactiver le bouton si pas connecté
  };

  // Vérifie la connexion toutes les 5 secondes
  interval = setInterval(monitorConnection, 5000);

  return () => clearInterval(interval); // Nettoyage lors du démontage
}, [checkServerConnection]); // Ajout de checkServerConnection comme dépendance


if (loading) {
  return <Spinner size="xl" color="teal.500" />;
}


// Filtrer les médias pour ne garder que les images dans la grille de prévisualisation
const fixedPreviewImages = box.mediaFiles
? box.mediaFiles.filter(media => 
  !media.filename.endsWith('.mp4') && 
  !media.filename.endsWith('.webm') &&
  !media.filename.endsWith('.mov') // Exclure également les fichiers .mov
)
: [];

  const handleOpenBoxStats = async () => {
    if (isRolling || closingOverlay) return; // Évite de lancer une nouvelle animation si une est déjà en cours ou si l'overlay est en cours de fermeture
    
    // Vérifiez la connexion au serveur avant de continuer
  const isConnected = await checkServerConnection();
  if (!isConnected) {
    toast({
      title: "Erreur de connexion",
      description: "Vous devez être connecté à Internet pour ouvrir une caisse.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
    return; // Arrêter l'exécution
  }
  
    if (!box || !box.mediaFiles || box.mediaFiles.length === 0) {
      console.error('Aucun média disponible pour ouvrir la caisse.');
      return;
    }

    try {
      // **Calculer la position du bouton avant de lancer l'animation**
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setButtonPosition({
          top: rect.top + window.scrollY, // Position absolue (prend en compte le scroll)
          left: rect.left + window.scrollX,
        });
      }
      // Appel à l'API pour incrémenter le nombre d'ouvertures de la caisse
      await incrementOpenCount(box._id); // Incrémenter le compteur ici

      if (audioRef.current) {
        await audioRef.current.play();
      }
    } catch (error) {
      console.error('Erreur lors de la lecture du son:', error);
    }

    // Démarrer l'animation en activant le verrouillage
    setIsRolling(true);
    setIsRollLocked(true); // Bloquer les interactions pendant que le rouleau tourne

  

    const chosenResult = selectResultBasedOnRarity(box.mediaFiles);
    if (!chosenResult) {
      console.error('Aucun média avec une rareté valide trouvé.');
      setIsRolling(false);
      setIsRollLocked(false); // Libérer les interactions même si aucun résultat n'a été choisi
      return;
    }

    setResult(chosenResult.filename);
    setRollImages(generateRollImages(box.mediaFiles, chosenResult.filename));

    await updateInventory(chosenResult);

    setTimeout(() => {
      setIsRolling(false);
      setIsRollLocked(false); // Libérer les interactions lorsque l'animation est terminée
      setBackgroundBlurred(true);
      // Ajout d'un délai pour réactiver le bouton
  setTimeout(() => {
    setIsButtonDisabled(false);
  }, 3000); // Ajout de 3 secondes après l'animation
  }, 8100);

    setTimeout(() => {
      toast({
        title: "Félicitations !",
        description: "Vous avez gagné un média ! Téléchargez-le avant qu'il ne disparaisse.",
        status: "success",
        duration: 3500,
        isClosable: true,
        position: "top",
      });
    }, 8100);
  };

  // Fonction pour incrémenter le nombre d'ouvertures via l'API
  const incrementOpenCount = async (boxId) => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/open-box/${boxId}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'incrémentation du compteur d\'ouvertures.');
      }

      const data = await response.json();
      console.log('Nombre d\'ouvertures mis à jour avec succès :', data.openCount);
    } catch (error) {
      console.error('Erreur lors de l\'incrémentation du compteur d\'ouvertures :', error);
    }
  };

  const updateInventory = async (media) => {
    try {
      const username = localStorage.getItem('username');
      const response = await fetch(`${apiUrl}/api/users/${username}/inventory`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filename: media.filename,
          rarity: media.rarity,
          boxTitle: box.title,
          boxId: box._id,
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de l\'inventaire.');
      }

      console.log('Inventaire mis à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'inventaire:', error);
    }
  };

  const selectResultBasedOnRarity = (mediaFiles) => {
    const rarityRates = {
      Élémentaire: 79.92,
      Singulier: 15.98,
      Intrigant: 3.2,
      Captivant: 0.64,
      Mythique: 0.26,
    };

    const validMedia = mediaFiles.filter((media) => media.rarity in rarityRates);
    const weightedMedia = [];

    validMedia.forEach((media) => {
      const rate = rarityRates[media.rarity];
      for (let i = 0; i < rate * 100; i++) {
        weightedMedia.push(media);
      }
    });

    if (weightedMedia.length === 0) {
      console.error('Aucun média avec une rareté valide trouvé.');
      return null;
    }

    const randomIndex = Math.floor(Math.random() * weightedMedia.length);
    return weightedMedia[randomIndex];
  };

  const getBlurredImagePath = (filename) => {
    return `${apiUrl}/uploads/floutees/${filename}`;
  };

  const rollContent = box.boxType === 'video' ? box.originalVideos : box.mediaFiles;

  const getAnimationImagePath = (filename) => {
    if (box.boxType === 'video' && (filename.endsWith('.mp4') || filename.endsWith('.webm') || filename.endsWith('.mov'))) {
      // Chemin vers la vidéo si c'est un fichier vidéo
      return `${apiUrl}/uploads/animations/${filename}`;
    } else {
      // Chemin vers l'image si c'est une image
      return `${apiUrl}/uploads/floutees/${filename}`;
    }
  };

  const generateRollImages = (mediaFiles, result) => {
    let rollImages = [];

    // Séparer les fichiers en photos et vidéos
    const photos = mediaFiles.filter(
      (file) => !file.filename.endsWith('.mp4') && !file.filename.endsWith('.webm') && !file.filename.endsWith('.mov')
    );
    const videos = mediaFiles.filter(
      (file) => file.filename.endsWith('.mp4') || file.filename.endsWith('.webm') || file.filename.endsWith('.mov')
    );

    const totalNeeded = 33;
    const repeatCount = Math.ceil(totalNeeded / rollContent.length);

    // Ajouter des photos et vidéos aléatoires
    const randomMediaFiles = [...photos, ...videos].sort(() => 0.5 - Math.random()); // Mélange aléatoire des images disponibles

    // Ajouter les images aléatoires
    rollImages = randomMediaFiles.map((media) => getAnimationImagePath(media.filename));

    // Ajouter les médias (images et vidéos) aléatoires au rouleau
      randomMediaFiles.forEach((media) => {
      rollImages.push(getAnimationImagePath(media.filename));
    });

    for (let i = 0; i < repeatCount; i++) {
      rollImages = [...rollImages, ...rollImages];
    }

    rollImages = rollImages.slice(0, totalNeeded); // Limiter à 31 images

    const fakeResultsCount = 3;
    for (let i = 0; fakeResultsCount > i; i++) {
      const randomFake = mediaFiles[Math.floor(Math.random() * mediaFiles.length)].filename;
      rollImages.push(getAnimationImagePath(randomFake));
    }

    const indexToInsertWinner = rollImages.length - fakeResultsCount - 1;
    rollImages[indexToInsertWinner] = `${apiUrl}/uploads/${result}`;

    return rollImages;
  };

  const handleImageClick = (e) => {
    e.preventDefault();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  
  const rarityRates = {
    Élémentaire: 79.92,
    Singulier: 15.98,
    Intrigant: 3.2,
    Captivant: 0.64,
    Mythique: 0.26,
  };

  const getRarityBadge = (rarity) => {
    const colors = {
      Élémentaire: 'gray',
      Singulier: 'blue',
      Intrigant: 'purple',
      Captivant: 'orange',
      Mythique: 'pink',
    };
    return (
      <Badge colorScheme={colors[rarity] || 'gray'}>{rarity}</Badge>
    );
  };

  if (!box) return <div>Chargement...</div>;

  return (
    
    <Box className={`box-detail ${backgroundBlurred ? 'blurred-background' : ''}`} maxW="800px" mx="auto" textAlign="center">
      
      {/* Utilisation de BoxHeader.js pour gérer l'affichage du titre, thumbnail, description, etc. */}
      {!box || !box.price ? (
        <Text fontSize="2xl">Chargement des détails de la caisse...</Text>
      ) : (
        <>
        {/* Utilisation de BoxHeader pour gérer l'affichage du titre, thumbnail, description, etc. */}
          <BoxHeader
          title={box.title}
          thumbnail={`${apiUrl}/uploads/${box.thumbnail}`}
          box={box} // Passe l'objet complet ici
          showFullDescription={showFullDescription}
          setShowFullDescription={setShowFullDescription}
          onInfoClick={openModal}
        />

          {!isRolling ? (
         // Si isRolling est faux, afficher le bouton animé
        <motion.div style={{ position: "relative", display: "inline-block" }}>
         <Particles />
         <motion.button
         ref={buttonRef} // Ajout de la référence ici
         onClick={async () => { // Ajoutez 'async' ici
        const isConnected = await checkServerConnection();
         if (!isConnected) {
         toast({
        title: "Erreur de connexion",
        description: "Vous devez être connecté à Internet pour ouvrir une caisse.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
        if (!isDisabled) {
          handleOpenBox(box._id, box.price);
          handleOpenBoxStats();
        } else {
          toast({
            title: "Solde de clés insuffisant",
            description: "Vous n'avez pas assez de clés pour ouvrir cette caisse.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        }
      }}
      disabled={isButtonDisabled || isDisabled}
      initial={{ scale: 1 }}
      animate={{
        boxShadow: [
          "0 0 15px rgba(255, 255, 255, 0.7)",
          "0 0 25px rgba(255, 255, 255, 0.9)",
          "0 0 35px rgba(255, 215, 0, 1)",
          "0 0 25px rgba(255, 255, 255, 0.9)",
          "0 0 15px rgba(255, 255, 255, 0.7)",
        ],
      }}
      whileHover={{
        scale: 1.1, // Agrandit légèrement lors du survol (PC uniquement)
        boxShadow: "0 0 40px rgba(255, 215, 0, 1)", // Accentuation de l'ombre
      }}
      whileTap={{
        scale: 0.95, // Réduit légèrement lors du clic
        boxShadow: "0 0 50px rgba(255, 215, 0, 1)", // Éclat intense temporaire
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse", // Répétition fluide en boucle
      }}
      style={{
        backgroundColor: "#3182CE",
        color: "white",
        padding: "12px 24px",
        fontSize: "16px",
        border: "none",
        borderRadius: "8px",
        cursor: isButtonDisabled || isDisabled ? "not-allowed" : "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        opacity: isButtonDisabled || isDisabled ? 0.6 : 1,
      }}
    >
      {isRolling ? "Ouverture en cours..." : "Ouvrir cette caisse"}
      </motion.button>
     </motion.div>
    ) : (
      <Spinner size="xl" color="rgba(255, 255, 255, 0)" />
     )}


          {/* <audio ref={audioRef} src="/sounds/open-box-sound.mp3" preload="auto" /> */}

           
          {/* Utilisation de BoxMediaPreview.js pour gérer l'affichage des vignettes des lignes de 6 images */}
          <BoxMediaPreview
          mediaFiles={fixedPreviewImages}
          rarityRates={rarityRates}
          getBlurredImagePath={getBlurredImagePath}
          getRarityBadge={getRarityBadge}
          />

           {/* Utilisation de RollAnimation.js pour gérer l'affichage du Rolling */}
          {isRolling && (
          <RollAnimation
          rollImages={rollImages}
          isRollLocked={isRollLocked}
          handleImageClick={handleImageClick}
          position={buttonPosition} // Transmet la position dynamique du bouton
          />
          )}
            


            {/* Utilisation de ResultOverlay.js pour gérer l'affichage gàgnànt */}
           {result && !isRolling && (
           <ResultOverlay result={result} apiUrl={apiUrl} onClose={handleCloseImage} />
           )}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Explication des Probabilités"
            className="modal"
            overlayClassName="overlay"
          >
            <Text fontSize="2xl" mb={4}>Comment Fonctionnent les Probabilités dans Nos Caisses</Text>
            <Text color="black">Ton texte explicatif ici</Text>
            <Button onClick={closeModal} colorScheme="red" mt={4}>
              Fermer
            </Button>
          </Modal>
        </>
      )}
    </Box>
  );
}

export default BoxDetailPage;