import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Input, InputGroup, InputRightElement, Button, Heading, Box, Text, useToast, Spinner } from '@chakra-ui/react'; // Importer Spinner
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import des icônes pour l'œil


function ModelLoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [certicode, setCerticode] = useState(''); // Ajout du champ Certicode
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement
  const [showPassword, setShowPassword] = useState(false); // État pour afficher ou masquer le mot de passe
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [autoSubmitEnabled, setAutoSubmitEnabled] = useState(true);
  const [attempts, setAttempts] = useState(0); // Pour limiter les tentatives de connexion

  const navigate = useNavigate();
  const toast = useToast(); // Initialisation du toast

  const apiUrl = process.env.REACT_APP_API_URL; // Utiliser l'URL de base via une variable d'environnement

  // Vérification des champs valides
  const areFieldsValid = useCallback(() => {
    return (
      email.trim() !== '' &&
      password.trim() !== '' &&
      certicode.trim() !== '' &&
      certicode.length === 6
    );
  }, [email, password, certicode]);


  // Gestion de la connexion
  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();

      // Vérifier les champs avant d'envoyer la requête
      if (!areFieldsValid()) {
        toast({
          title: 'Champs invalides',
          description: 'Veuillez remplir correctement tous les champs.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        return;
      }

      if (isLoading || attempts >= 3) return; // Limiter à 3 tentatives

      setIsLoading(true);

    try {
      const response = await fetch(`${apiUrl}/api/model/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, certicode }), // Inclure Certicode dans la requête
      });

      const data = await response.json();

      if (response.ok) {
        setTimeout(() => {
          setIsLoading(false);
          setHasLoginFailed(false); // Réinitialiser les échecs
          setAutoSubmitEnabled(true); // Activer la soumission automatique

          // Stocker les informations de connexion dans le localStorage
          localStorage.setItem('modelId', data.model._id); // Stocker l'ID du modèle dans le localStorage
          localStorage.setItem('username', data.model.username); 
          localStorage.setItem('role', 'model'); // Stocker le rôle 'model' dans le localStorage
          
          // Afficher un toast de bienvenue
          toast({
            title: "Connexion réussie ! 🎉",
            description: `Bienvenue, ${data.model.username} !`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });

          // Redirection vers la page d'accueil après connexion réussie
          navigate('/');
        }, 1000); // Ajouter un délai de 1 seconde pour l'effet visuel
      } else {
        setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
        setHasLoginFailed(true); // Marquer l'échec
        setTimeout(() => {
        setAutoSubmitEnabled(false); // Désactiver la soumission auto
        }, 100);
        setAttempts((prev) => prev + 1); // Incrémenter les tentatives

        toast({
          title: "Erreur de connexion",
          description: data.message || 'Email, mot de passe ou certicode incorrect.',
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
      setHasLoginFailed(true);
      setTimeout(() => {
      setAutoSubmitEnabled(false);
    }, 100);

      console.error('Erreur serveur:', error);

      toast({
        title: "Erreur serveur",
        description: 
        "Une erreur s'est produite lors de la connexion. Veuillez réessayer.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  },
    [email, password, certicode, isLoading, apiUrl, toast, navigate, areFieldsValid, attempts]
  );

  // Réinitialiser l'état d'échec quand les champs changent
  useEffect(() => {
    if (hasLoginFailed) {
      setHasLoginFailed(false);
      setAutoSubmitEnabled(true);
    }
  }, [email, password, certicode,hasLoginFailed, areFieldsValid]);


  // Soumettre automatiquement quand les champs sont valides
  useEffect(() => {
    if (areFieldsValid() && autoSubmitEnabled && !hasLoginFailed) {
      handleLogin(new Event('submit')); // Appel de la fonction handleLogin
    }
  }, [email, password, certicode, areFieldsValid, handleLogin, autoSubmitEnabled, hasLoginFailed]);


  return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        width="100vw" // Utiliser toute la largeur de l'écran
        bgGradient="linear(to-r,rgb(0, 0, 0),rgb(0, 0, 0))"
      >
        <Box
          as="form"
          onSubmit={handleLogin}
          p="6"
          rounded="lg"
          boxShadow="0 0 20px rgba(102, 0, 255, 0.72)"
          bg="black" // Changer la couleur du fond en noir
          width="400px"
          transition="all 0.3s ease"
          _hover={{
            boxShadow: '0 0 30px rgba(102, 0, 255, 0.72)',
            transform: 'scale(1.05)',
          }}
        >
          <Heading
          textAlign="center"
          mb={6}
          fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }} // Adaptation à différentes tailles d'écran
           sx={{
           background: 'linear-gradient(90deg, red, orange, yellow, green, indigo, violet)',
           WebkitBackgroundClip: 'text',
           color: 'transparent',
           backgroundSize: '200% 200%', // Permet au dégradé de mieux couvrir le texte
           backgroundPosition: 'center', // Centre le dégradé sur le texte
           transition: 'transform 0.2s',
          _hover: {
          transform: 'scale(1.1)',
          },
         }}
         >
          Onlybox
         </Heading>
  
          <Heading as="h2" mb={4} textAlign="center" color="white" fontWeight="bold">
           Se connecter en tant que Modèle
          </Heading>
  
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mb={3}
            borderColor="#9146FF" // Bordure verte
            focusBorderColor="#9146FF" // Bordure verte lumineuse au focus
            _hover={{ borderColor: '#9146FF' }} // Bordure verte au survol
            borderRadius="full" // Coins complètement arrondis // Utilise `sx` pour ajouter `!important`
            h="40px" // Hauteur pour un effet de pilule
            px={4} // Padding horizontal pour aérer
            fontSize="lg" // Taille de texte légèrement plus grande
            boxShadow="0 0 10px rgba(102, 0, 255, 0.72)" // Ombre subtile pour l'effet
            bg="#1e1e1e" // Fond noir ou gris foncé
            color="#fff" // Texte blanc pour le contraste
            disabled={isLoading}
          />
  
          <InputGroup mb={3}>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              borderColor="#9146FF"
              focusBorderColor="#9146FF"
              _hover={{ borderColor: '#9146FF' }}
              borderradius="full"
              h="40px" // Hauteur pour un effet de pilule
              px={4} // Padding horizontal pour aérer
              fontSize="lg"
              boxShadow="0 0 10px rgba(102, 0, 255, 0.72)" // Ombre subtile pour l'effet
              bg="#1e1e1e" // Fond noir ou gris foncé
              color="#fff" // Texte blanc pour le contraste
              disabled={isLoading}
            />
            <InputRightElement width="3rem">
              <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </Button>
            </InputRightElement>
          </InputGroup>
  
          <Input
            type="text"
            placeholder="Certicode (Code personnel)"
            value={certicode}
            onChange={(e) => setCerticode(e.target.value)}
            mb={3}
            maxLength={6}
            pattern="\d{6}"
            inputMode="numeric"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            borderColor="#9146FF"
            focusBorderColor="#9146FF"
            _hover={{ borderColor: '#9146FF' }}
            borderradius="full"
            h="40px" // Hauteur pour un effet de pilule
            px={4} // Padding horizontal pour aérer
            fontSize="lg"
            boxShadow="0 0 10px rgba(102, 0, 255, 0.72)" // Ombre subtile pour l'effet
            bg="#1e1e1e" // Fond noir ou gris foncé
            color="#fff" // Texte blanc pour le contraste
            disabled={isLoading}
          />
  
         <Button
         type="submit"
         width="100%"
         bgGradient="linear(to-r,rgb(140, 0, 255),rgb(130, 50, 205))" // Dégradé vert
         color="black" // Couleur du texte
         _hover={{
         bgGradient: 'linear(to-r,rgb(130, 50, 205),rgb(140, 0, 255))', // Dégradé inversé au survol
         transform: 'scale(1.05)', // Légère augmentation au survol
         }}
         size="lg"
         disabled={isLoading} // Désactive le bouton si "isLoading" est vrai
         >
         {isLoading ? <Spinner size="sm" /> : 'Se connecter'}
         </Button>
  
         <Text
         mt={4}
         textAlign="center"
         color="green.400" // Vert similaire
        fontWeight="bold" // Texte en gras
        fontSize="sm" // Taille légèrement plus petite
        textTransform="uppercase" // Pour tout mettre en majuscules
        >
        <Link
      to="/forgot-password"
      style={{
        textDecoration: 'none', // Retirer le soulignement
        color: '#9146FF', // Utilise la même couleur que le parent (vert)
      }}
    >
      Mot de passe oublié ?
    </Link>
    </Text>
   </Box>
  </Box>
    );
  }

export default ModelLoginPage;
