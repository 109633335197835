// components/HomePage/StoryList.js
import React from 'react';
import { HStack, Box, Circle, Image, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

const StoryList = ({ stories, maxVisibleStories, handleStoryClick, selectedStory, isModalOpen, closeModal, showNextStory, showPreviousStory, apiUrl, initialOpen }) => {
  return (
    <>
      {/* Liste des Stories */}
      <HStack
        as={motion.div}
        overflowX="auto"
        spacing={4}
        className="stories-container"
        padding={2}
        justifyContent="center"
        width="100%"
        maxWidth="100vw"
        alignItems="center"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none', // Cache la scrollbar sur les appareils modernes
          },
        }}
      >
        {stories.slice(0, maxVisibleStories).map((story) => (
          <Box key={story._id} textAlign="center">
            <MotionBox
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              padding="4px"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => handleStoryClick(story)}
              sx={{
                bgGradient: "linear(to-r, pink.500, orange.500, yellow.500, green.500, blue.500, purple.500)",
                padding: "4px",
                borderRadius: "full",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden", // S'assurer que le contenu reste rond
              }}
            >
              <Circle size="72px" bg="white" overflow="hidden">
                <Image
                  src={`${apiUrl}/uploads/avatars/${story.avatar}`}
                  alt={story.creator}
                  boxSize="64px"
                  borderRadius="full"
                  objectFit="cover"
                  opacity={story.viewed ? 0.7 : 1} // Rendre l'image plus terne si visionnée
                />
              </Circle>
            </MotionBox>
            <Text fontSize="sm" mt={2} color="white">
              {story.creator}
            </Text>
          </Box>
        ))}
      </HStack>

      {/* Modal pour afficher les détails d'une Story */}
      {selectedStory && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedStory.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AnimatePresence mode="wait">
                <motion.div
                  key={selectedStory._id}
                  initial={initialOpen ? { opacity: 0, y: 50 } : { opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  exit={initialOpen ? { opacity: 0, y: 50 } : { opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }} // Transition fluide pour les stories suivantes
                  style={{ display: 'flex', height: '100%', cursor: 'pointer' }}
                  onClick={showNextStory}
                >
                  <Box
                    flex="1"
                    onClick={(e) => {
                      e.stopPropagation();
                      showPreviousStory();
                    }}
                    style={{ cursor: 'pointer' }}
                  ></Box>
                  {/* Affichage image ou vidéo */}
                  {selectedStory.media.endsWith('.mp4') ? (
                    <video
                      src={`${apiUrl}/uploads/stories/${selectedStory.media}`}
                      width="100%"
                      controls
                      autoPlay
                      muted
                      loop
                      style={{ borderRadius: '8px', objectFit: 'cover' }}
                    />
                  ) : (
                    <Image
                      src={`${apiUrl}/uploads/stories/${selectedStory.media}`}
                      alt={selectedStory.creator}
                      width="100%"
                      borderRadius="md"
                      objectFit="cover"
                    />
                  )}
                  <Box flex="1" onClick={showNextStory} style={{ cursor: 'pointer' }}></Box>
                </motion.div>
              </AnimatePresence>
              <Text mt={2}>
                <strong>Créateur:</strong> {selectedStory.creator}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={closeModal}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default StoryList;
