import React from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Box,
  Image,
  ButtonGroup,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const CreateCarouselForm = ({
  carouselImages,
  setCarouselImages, // Ajout du setter pour gérer l'état des images
  handleCarouselImageUpload,
  handleDeleteCarouselImage,
  handleCarouselSubmit,
}) => {
  // Fonction pour gérer le drag-and-drop
  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // Pas de destination valide

    const items = Array.from(carouselImages); // Copie des images
    const [reorderedItem] = items.splice(result.source.index, 1); // Retirer l'image déplacée
    items.splice(result.destination.index, 0, reorderedItem); // Insérer à la nouvelle position

    setCarouselImages(items); // Mettre à jour l'état
  };

  // Fonction pour déplacer une image avec des boutons
  const moveImage = (fromIndex, toIndex) => {
    const items = [...carouselImages];
    const [movedItem] = items.splice(fromIndex, 1);
    items.splice(toIndex, 0, movedItem);
    setCarouselImages(items); // Mettre à jour l'état
  };

  return (
    <VStack as="form" spacing={4} onSubmit={handleCarouselSubmit}>
      <FormControl isRequired>
        <FormLabel>Images du Carrousel (1 à 10)</FormLabel>
        <Input
          type="file"
          multiple
          onChange={handleCarouselImageUpload}
          accept="image/webp, image/jpeg, image/png"
        />
      </FormControl>

      {/* Visualiser les images sélectionnées avant soumission avec drag-and-drop */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="carouselImages" direction="horizontal">
          {(provided) => (
            <SimpleGrid
              columns={[2, null, 4]}
              spacing={4}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {carouselImages.map((image, index) => (
                <Draggable key={index} draggableId={String(index)} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      position="relative"
                    >
                      <Image
                        src={
                          typeof image === "string"
                            ? `${process.env.REACT_APP_API_URL}/uploads/${image}`
                            : URL.createObjectURL(image)
                        }
                        alt={`Carousel Image ${index + 1}`}
                        boxSize="100px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                      {/* Bouton pour supprimer l'image */}
                      <Button
                        size="xs"
                        colorScheme="red"
                        position="absolute"
                        top="0"
                        right="0"
                        onClick={() => handleDeleteCarouselImage(index)}
                      >
                        X
                      </Button>
                      {/* Boutons pour réorganiser */}
                      <ButtonGroup size="sm" mt={2} isAttached>
                        <Button
                          onClick={() => moveImage(index, index - 1)}
                          isDisabled={index === 0}
                        >
                          Monter
                        </Button>
                        <Button
                          onClick={() => moveImage(index, index + 1)}
                          isDisabled={index === carouselImages.length - 1}
                        >
                          Descendre
                        </Button>
                      </ButtonGroup>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </SimpleGrid>
          )}
        </Droppable>
      </DragDropContext>

      {/* Bouton pour créer le carrousel */}
      <Button type="submit" colorScheme="teal">
        Créer Carrousel
      </Button>
    </VStack>
  );
};

export default CreateCarouselForm;