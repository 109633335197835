import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button, Input, Box, Heading, Text } from '@chakra-ui/react'; // Importer useToast et les composants Chakra

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // Pour la redirection après la soumission
  const toast = useToast(); // Initialiser useToast

  const apiUrl = process.env.REACT_APP_API_URL; // Utiliser la variable d'environnement pour l'API


  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/model/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Lien de réinitialisation envoyé.',
          description: 'Un lien a été envoyé à votre email pour réinitialiser votre mot de passe et certicode.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        // Rediriger vers la page de connexion des modèles
        navigate('/model/login');
        } else {
        // Gérer les erreurs spécifiques
        if (response.status === 429) {
          // Cas limiteur (trop de requêtes)
          toast({
            title: 'Trop de demandes.',
            description: data.message || 'Vous avez atteint la limite de tentatives. Veuillez réessayer plus tard.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        } else {
        // Autres erreurs (email non trouvé, etc.)
        toast({
          title: 'Erreur.',
          description: data.message || 'Une erreur est survenue lors de la demande de réinitialisation.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialisation:', error);

      toast({
        title: 'Erreur serveur.',
        description: 'Une erreur s\'est produite lors de la demande. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
      <Box 
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        width="100vw" // Utiliser toute la largeur de l'écran
        bgGradient="linear(to-r,rgb(0, 0, 0),rgb(0, 0, 0))"
        >
        <Box
          as="form"
          onSubmit={handleForgotPassword}
          p="6"
          rounded="lg"
          boxShadow="0 0 20px rgba(102, 0, 255, 0.72)"
          bg="black" // Changer la couleur du fond en noir
          width="400px"
          transition="all 0.3s ease"
          _hover={{
            boxShadow: '0 0 30px rgba(102, 0, 255, 0.72)',
            transform: 'scale(1.05)',
          }}
        >
          <Heading as="h2" mb={4} textAlign="center" color="white" fontWeight="bold">
            Mot de passe et Certicode oubliés
          </Heading>
  
          <Text mb={4} textAlign="center" color="white">
           Saisissez votre email pour recevoir le lien de réinitialisation.
          </Text>
  
          <Input
            type="email"
            placeholder="Entrez votre email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mb={4}
            borderColor="#9146FF" // Bordure verte
            focusBorderColor="#9146FF" // Bordure verte lumineuse au focus
            _hover={{ borderColor: '#9146FF' }} // Bordure verte au survol
            borderRadius="full" // Coins complètement arrondis // Utilise `sx` pour ajouter `!important`
            h="40px" // Hauteur pour un effet de pilule
            px={4} // Padding horizontal pour aérer
            fontSize="lg" // Taille de texte légèrement plus grande
            boxShadow="0 0 10px rgba(102, 0, 255, 0.72)" // Ombre subtile pour l'effet
            bg="#1e1e1e" // Fond noir ou gris foncé
            color="#fff" // Texte blanc pour le contraste
          />
  
            <Button
           type="submit"
           width="100%"
           bgGradient="linear(to-r,rgb(140, 0, 255),rgb(130, 50, 205))" // Dégradé vert
           color="black" // Couleur du texte
           _hover={{
           bgGradient: 'linear(to-r,rgb(130, 50, 205),rgb(140, 0, 255))', // Dégradé inversé au survol
           transform: 'scale(1.05)', // Légère augmentation au survol
           }}
           size="lg" // Taille large pour uniformité
           >
           Envoyer le lien de réinitialisation
          </Button>
        </Box>
      </Box>
    );
  }

export default ForgotPasswordPage;
