import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button, Input, Box, Heading, Text } from '@chakra-ui/react'; // Importer les composants Chakra et le toast

function ForgotPasswordPageUser() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // Pour la redirection après la soumission
  const toast = useToast(); // Initialiser useToast

  const apiUrl = process.env.REACT_APP_API_URL; // Utilisation de la variable d'environnement pour l'API

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/user/forgot-password-user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        // Utiliser un toast pour informer l'utilisateur au lieu d'un alert
        toast({
          title: 'Lien de réinitialisation envoyé.',
          description: 'Un lien a été envoyé à votre email pour réinitialiser votre mot de passe et certicode.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        // Redirection vers la page de connexion
        navigate('/login');
    } else {
      // Gérer les erreurs spécifiques
      if (response.status === 429) {
        // Cas limiteur (Trop de requêtes)
        toast({
          title: 'Trop de demandes.',
          description: data.message || 'Vous avez atteint la limite de tentatives. Veuillez réessayer plus tard.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } else {
        // Utiliser un toast pour afficher l'erreur
        toast({
          title: 'Erreur.',
          description: data.message || 'Erreur lors de la demande de réinitialisation.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialisation:', error);
      // Utiliser un toast pour afficher une erreur serveur
      toast({
        title: 'Erreur serveur.',
        description: 'Une erreur s\'est produite lors de la demande. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100vw" // Utiliser toute la largeur de l'écran
      bgGradient="linear(to-r,rgb(0, 0, 0),rgb(0, 0, 0))"
      >
      <Box
        as="form"
        onSubmit={handleForgotPassword}
        p="6"
        rounded="lg"
        boxShadow="0 0 20px rgba(0, 255, 42, 0.72)"
        bg="black" // Changer la couleur du fond en noir
        width="400px"
        transition="all 0.3s ease"
        _hover={{
          boxShadow: '0 0 30px rgba(21, 255, 0, 0.8)',
          transform: 'scale(1.05)',
        }}
      >
        <Heading as="h2" mb={4} textAlign="center" color="white" fontWeight="bold">
          Mot de passe et Certicode oubliés
        </Heading>

        <Text mb={4} textAlign="center" color="white">
          Entrez votre adresse email pour recevoir un lien de réinitialisation de mot de passe et certicode.
        </Text>

        <Input
          type="email"
          placeholder="Entrez votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={4}
          borderColor="green.400" // Bordure verte
          focusBorderColor="green.500" // Bordure verte lumineuse au focus
          _hover={{ borderColor: 'green.500' }} // Bordure verte au survol
          borderRadius="full" // Coins complètement arrondis // Utilise `sx` pour ajouter `!important`
          h="40px" // Hauteur pour un effet de pilule
          px={4} // Padding horizontal pour aérer
          fontSize="lg" // Taille de texte légèrement plus grande
          boxShadow="0 0 10px rgba(0, 255, 0, 0.3)" // Ombre subtile pour l'effet
          bg="#1e1e1e" // Fond noir ou gris foncé
          color="#fff" // Texte blanc pour le contraste
        />

          <Button
         type="submit"
         width="100%"
         bgGradient="linear(to-r, #7FFF00, #32CD32)" // Dégradé vert
         color="black" // Couleur du texte
         _hover={{
         bgGradient: 'linear(to-r, #32CD32, #7FFF00)', // Dégradé inversé au survol
         transform: 'scale(1.05)', // Légère augmentation au survol
         }}
         size="lg" // Taille large pour uniformité
         >
         Envoyer le lien de réinitialisation
        </Button>
      </Box>
    </Box>
  );
}

export default ForgotPasswordPageUser;
