import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Heading,
  Text,
  VStack,
  Input,
  List,
  ListItem, 
  Link,     
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate pour la redirection

const apiUrl = process.env.REACT_APP_API_URL; // Récupérer l'URL de base depuis la variable d'environnement

// Liste des pays de l'Union Européenne
const europeanCountries = [
  "France",
  "Belgique",
  "Allemagne",
  "Luxembourg",
  "Autriche",
  "Bulgarie",
  "Croatie",
  "Chypre",
  "République tchèque",
  "Danemark",
  "Estonie",
  "Finlande",
  "Grèce",
  "Hongrie",
  "Irlande",
  "Italie",
  "Lettonie",
  "Lituanie",
  "Malte",
  "Pays-Bas",
  "Pologne",
  "Portugal",
  "Roumanie",
  "Slovaquie",
  "Slovénie",
  "Espagne",
  "Suède",
];

function SettingsPagemodel() {
  const [modelInfo, setModelInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      country: '',
    },
    dateOfBirth: '',
  });

  // État initial pour les informations modèle
  const [isLoading, setIsLoading] = useState(true); // Indicateur de chargement
  const [isEditing, setIsEditing] = useState(false); // Indicateur pour le mode édition
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = React.useRef();
  const toast = useToast();
  const navigate = useNavigate(); // Utiliser useNavigate pour rediriger


  // Fonction utilitaire pour convertir une date ISO en JJ/MM/AAAA
const formatDate = (isoDate) => {
  if (!isoDate) return '';
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

  // Mise à jour lors de la récupération des données
useEffect(() => {
  const fetchModelInfo = async () => {
    try {
      const modelId = localStorage.getItem('modelId');
      const response = await fetch(`${apiUrl}/api/model/${modelId}`);
      const data = await response.json();

      if (response.ok) {
        const formattedData = {
          ...data,
          dateOfBirth: formatDate(data.dateOfBirth), // Convertir la date
        };
        setModelInfo(formattedData);
      } else {
        toast({
          title: 'Erreur lors de la récupération des informations.',
          description: data.message || 'Une erreur est survenue.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations personnelles:', error);
      toast({
        title: 'Erreur serveur.',
        description: 'Impossible de récupérer vos informations pour le moment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  fetchModelInfo();
}, [toast]);

  // Fonction pour activer/désactiver le mode édition
  const handleEdit = () => {
    setIsEditing(!isEditing); // Active ou désactive le mode édition
  };

  // Fonction pour gérer les modifications dans les champs de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('address.')) {
      // Gérer les champs d'adresse
      const addressField = name.split('.')[1];
      setModelInfo((prevInfo) => ({
        ...prevInfo,
        address: {
          ...prevInfo.address,
          [addressField]: value,
        },
      }));
    } else {
      setModelInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  // Fonction utilitaire pour convertir JJ/MM/AAAA en ISO (AAAA-MM-JJ)
const parseToISO = (formattedDate) => {
  if (!formattedDate) return '';
  const [day, month, year] = formattedDate.split('/');
  return `${year}-${month}-${day}`;
};

// Fonction de sauvegarde
const handleSave = async () => {
  const modelId = localStorage.getItem('modelId');

  try {
    const updatedInfo = {
      ...modelInfo,
      dateOfBirth: parseToISO(modelInfo.dateOfBirth), // Reconvertir la date
    };

    const response = await fetch(`${apiUrl}/api/model/update/${modelId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedInfo),
    });

    const data = await response.json();

    if (response.ok) {
      toast({
        title: 'Informations mises à jour.',
        description: 'Vos informations personnelles ont été mises à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsEditing(false); // Quitte le mode édition
    } else {
      toast({
        title: 'Erreur lors de la mise à jour.',
        description: data.message || 'Une erreur est survenue.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour des informations:', error);
    toast({
      title: 'Erreur serveur.',
      description: 'Impossible de mettre à jour vos informations pour le moment.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }
};

  // Fonction pour ouvrir la boîte de dialogue de suppression
  const openDeleteDialog = () => setIsOpen(true);

  // Fonction pour fermer la boîte de dialogue de suppression
  const closeDeleteDialog = () => setIsOpen(false);

  // Fonction pour supprimer le compte
  const handleDeleteAccount = async () => {
    const modelId = localStorage.getItem('modelId'); // Récupérer l'ID du modèle depuis le localStorage
    const apiUrl = process.env.REACT_APP_API_URL; // Re-déclarer apiUrl si nécessaire
    try {
      const response = await fetch(`${apiUrl}/api/model/delete-account/${modelId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Compte supprimé.',
          description: 'Votre compte a été supprimé définitivement.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Supprimer les informations de connexion du localStorage
        localStorage.removeItem('modelId');
        localStorage.removeItem('username');
        localStorage.removeItem('role');

        // Rediriger vers la page d'accueil après la suppression
        navigate('/'); // Redirige vers la HomePage

      } else {
        toast({
          title: 'Erreur lors de la suppression.',
          description: data.message || 'Une erreur est survenue.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error);
      toast({
        title: 'Erreur serveur.',
        description: 'Impossible de supprimer votre compte pour le moment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      closeDeleteDialog(); // Ferme la boîte de dialogue après la suppression
    }
  };

  return (
    <Box p={8}>
      <Heading mb={6}>Paramètres</Heading>
      <Tabs variant="soft-rounded" colorScheme="pink">
        <TabList>
          <Tab>Compte</Tab>
          <Tab>Informations</Tab>
        </TabList>

        <TabPanels>
          {/* Onglet COMPTE */}
          <TabPanel>
            <Heading size="md" mb={4}>Informations personnelles</Heading>
            {isLoading ? (
              <Text>Chargement des informations...</Text>
            ) : (
              <VStack align="start" spacing={4}>
                <Text>Prénom</Text>
                <Input
                  type="text"
                  placeholder="Prénom"
                  name="firstName"
                  value={modelInfo.firstName}
                  isDisabled={!isEditing}
                  style={{
                    border: '1px solid #ccc',
                    padding: '8px',
                    borderRadius: '4px',
                    backgroundColor: !isEditing ? '#f5f5f5' : 'white',
                  }}
                />
                <Text>Nom</Text>
                <Input
                  type="text"
                  placeholder="Nom"
                  name="lastName"
                  value={modelInfo.lastName}
                  isDisabled={!isEditing}
                  style={{
                    border: '1px solid #ccc',
                    padding: '8px',
                    borderRadius: '4px',
                    backgroundColor: !isEditing ? '#f5f5f5' : 'white',
                  }}
                />
                <Text>Email</Text>
                <Input
                  placeholder="Email"
                  name="email"
                  value={modelInfo.email}
                  onChange={handleChange}
                  isDisabled={!isEditing}
                />
                <Text>Adresse ligne 1</Text>
                <Input
                  placeholder="Adresse ligne 1"
                  name="address.addressLine1"
                  value={modelInfo.address.addressLine1}
                  onChange={handleChange}
                  isDisabled={!isEditing}
                />
                <Text>Adresse ligne 2</Text>
                <Input
                  placeholder="Adresse ligne 2"
                  name="address.addressLine2"
                  value={modelInfo.address.addressLine2}
                  onChange={handleChange}
                  isDisabled={!isEditing}
                />
                <Text>Ville</Text>
                <Input
                  placeholder="Ville"
                  name="address.city"
                  value={modelInfo.address.city}
                  onChange={handleChange}
                  isDisabled={!isEditing}
                />
                <Text>Code postal</Text>
                <Input
                  placeholder="Code postal"
                  name="address.postalCode"
                  value={modelInfo.address.postalCode}
                  onChange={handleChange}
                  isDisabled={!isEditing}
                />
                <Text>Pays</Text>
                 <select
                name="address.country"
                 value={modelInfo.address.country}
                  onChange={handleChange}
                 disabled={!isEditing} // Désactiver si pas en mode édition
                 style={{
                 border: '1px solid #ccc',
                 padding: '8px',
                borderRadius: '4px',
                backgroundColor: !isEditing ? '#f5f5f5' : 'white',
                width: '100%',
                 }}
                 >
                 <option value="">Sélectionnez votre pays</option>
                 {europeanCountries.map((country) => (
                 <option key={country} value={country}>
                 {country}
                 </option>
                 ))}
                 </select>
                <Text>Date de naissance</Text>
                <Input
                type="text"
                placeholder="JJ/MM/AAAA"
                name="dateOfBirth"
                value={modelInfo.dateOfBirth}
                onChange={(e) => {
                const value = e.target.value;
                // Validation basique pour s'assurer du format JJ/MM/AAAA
               if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(value) || value === '') {
               handleChange(e); // Utiliser handleChange pour mettre à jour l'état
               }
              }}
               isDisabled={!isEditing}
               style={{
               border: '1px solid #ccc',
               padding: '8px',
                borderRadius: '4px',
               backgroundColor: !isEditing ? '#f5f5f5' : 'white',
              }}
               />

                {!isEditing ? (
                  <Button colorScheme="blue" onClick={handleEdit}>
                    Modifier
                  </Button>
                ) : (
                  <Button colorScheme="green" onClick={handleSave}>
                    Sauvegarder
                  </Button>
                )}
              </VStack>
            )}
            <Button
              colorScheme="red"
              mt={6}
              onClick={openDeleteDialog}
            >
              Supprimer mon compte
            </Button>

            {/* Boîte de dialogue de confirmation */}
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={closeDeleteDialog}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Supprimer définitivement le compte
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Êtes-vous sûr de vouloir supprimer définitivement votre compte ? Cette action est irréversible.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={closeDeleteDialog}>
                      Annuler
                    </Button>
                    <Button colorScheme="red" onClick={handleDeleteAccount} ml={3}>
                      Supprimer
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </TabPanel>

          {/* Onglet INFORMATIONS */}
          <TabPanel>
            <Heading size="md" mb={4}>Informations</Heading>
            <List spacing={3}>
              <ListItem>
                <Link href="/terms-of-service" color="teal.500">Conditions générales de vente</Link>
              </ListItem>
              <ListItem>
                <Link href="/cookies" color="teal.500">Cookies</Link>
              </ListItem>
              <ListItem>
                <Link href="/legal" color="teal.500">Mentions légales</Link>
              </ListItem>
              <ListItem>
                <Link href="/privacy" color="teal.500">Confidentialité</Link>
              </ListItem>
              <ListItem>
                <Link href="/child-protection" color="teal.500">Protection de l'enfance</Link>
              </ListItem>
              <ListItem>
                <Link href="/anti-exploitation" color="teal.500">Politique anti-exploitation</Link>
              </ListItem>
              <ListItem>
                <Link href="/content-policy" color="teal.500">Politique des contenus</Link>
              </ListItem>
            </List>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default SettingsPagemodel;