import React from "react";
import { motion } from "framer-motion";

const Particles = () => {
  // Générer 20 particules
  const particles = Array.from({ length: 20 });
  const colors = [
    "rgba(255, 255, 255, 0.8)", // Blanc
    "rgba(255, 215, 0, 0.8)",   // Doré
    "rgba(173, 216, 230, 0.8)"  // Bleu clair
  ];

  return (
    <div style={{ position: "absolute", 
    top: "50%", // Centre verticalement sur le bouton
    left: "50%", // Centre horizontalement sur le bouton
    transform: "translate(-50%, -50%)", // Correction pour aligner parfaitement au centre
    width: "100px", // Zone où apparaîtront les particules 
    height: "100%", 
    pointerEvents: "none" }}>
      {particles.map((_, index) => (
        <motion.div
          key={index}
          initial={{
            opacity: 0,
            x: Math.random() * 200 - 100, // Position X aléatoire
            y: Math.random() * 200 - 100, // Position Y aléatoire
            scale: 0.5,
          }}
          animate={{
            opacity: [0, 1, 0],
            x: Math.random() * 200 - 100,
            y: Math.random() * 200 - 100,
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            delay: Math.random() * 2, // Délai aléatoire
          }}
          style={{
            position: "absolute",
            width: "10px",
            height: "10px",
            backgroundColor: colors[Math.floor(Math.random() * colors.length)],
            borderRadius: "50%",
            boxShadow: "0 0 8px rgba(255, 255, 255, 0.5)", // Lueur autour des particules
          }}
        ></motion.div>
      ))}
    </div>
  );
};

export default Particles;
