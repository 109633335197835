import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Text, Input, Heading, Button, useToast, Flex } from '@chakra-ui/react';
import { FaLock } from 'react-icons/fa';


// Objet de correspondance des codes ISO à leurs noms complets
const isoToCountryName = {
  DE: "Allemagne",
  FR: "France",
  BE: "Belgique",
  LU: "Luxembourg",
  AT: "Autriche",
  BG: "Bulgarie",
  HR: "Croatie",
  CY: "Chypre",
  CZ: "RépubliqueTchèque",
  DK: "Danemark",
  EE: "Estonie",
  FI: "Finlande",
  GR: "Grèce",
  HU: "Hongrie",
  IE: "Irlande",
  IT: "Italie",
  LV: "Lettonie",
  LT: "Lituanie",
  MT: "Malte",
  NL: "PaysBas",
  PL: "Pologne",
  PT: "Portugal",
  RO: "Roumanie",
  SK: "Slovaquie",
  SI: "Slovénie",
  ES: "Espagne",
  SE: "Suède"
};

// Taux de TVA par pays
const vatRates = {
  France: 20,
  Belgique: 21,
  Allemagne: 19,
  Luxembourg: 17,
  Autriche: 20,
  Bulgarie: 20,
  Croatie: 25,
  Chypre: 19,
  RépubliqueTchèque: 21,
  Danemark: 25,
  Estonie: 20,
  Finlande: 24,
  Grèce: 24,
  Hongrie: 27,
  Irlande: 23,
  Italie: 22,
  Lettonie: 21,
  Lituanie: 21,
  Malte: 18,
  PaysBas: 21,
  Pologne: 23,
  Portugal: 23,
  Roumanie: 19,
  Slovaquie: 20,
  Slovénie: 22,
  Espagne: 21,
  Suède: 25,
  Suisse: 7.7,
  RoyaumeUni: 20
};


// Lazy loading de la bibliothèque Stripe
const stripePromise = loadStripe('pk_test_51Q6YxjHtN7Es0vfXbQWK4Xvk6cklQqJWbYfiCLWzc6kVuhfWDBZ3ePJ5de05yKDNoAeLgIA8zfKgjDzkaigJwjC1008xeBaEky');

const calculateBonusKeys = (keyCount) => {
  if (keyCount >= 10) {
    return 2; // 2 clés bonus pour 10 clés ou plus
  } else if (keyCount >= 5) {
    return 1; // 1 clé bonus pour 5 clés ou plus
  }
  return 0; // Aucun bonus en dessous de 5 clés
};

function CheckoutForm({ totalPrice, keyCount, inconsistencyDetected, verificationStatus, ipCountry }) {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fonction pour enregistrer l'achat au backend
  const savePurchaseToBackend = async (keyCount, totalPrice, userAddress) => {
    try {
      const userId = localStorage.getItem('userId');
      const countryCode = localStorage.getItem('userCountry'); // Supposons que le pays déclaré est stocké ici

      // Validation des données locales
      if (!userId || !countryCode) {
        console.error("Utilisateur non connecté ou countryCode non défini.");
        toast({
          title: "Erreur",
          description: "Impossible de procéder : utilisateur ou pays non défini.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Appel à l'API pour enregistrer l'achat
      const response = await fetch(`${apiUrl}/api/purchase/create-key-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,          // ID utilisateur
          keyCount,        // Nombre de clés achetées
          totalPrice,      // Prix total avec TVA
          countryCode,     // Code du pays déclaré par l'utilisateur
          address: userAddress || null, // Adresse complète si disponible
          ipCountry,       // Pays détecté par l'IP
          inconsistencyDetected, // Incohérence détectée
        }),
      });

      // Vérification du succès de la requête
      if (response.ok) {
        console.log("Achat enregistré avec succès dans la base de données.");
        toast({
          title: "Succès",
          description: "Votre achat a été enregistré avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const errorText = await response.text();
        console.error("Erreur lors de l'enregistrement de l'achat :", errorText);
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de l'enregistrement de l'achat.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'achat au backend :", error);
      toast({
        title: "Erreur serveur",
        description: "Impossible de contacter le serveur pour enregistrer votre achat.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    console.log("Stripe chargé :", stripe);
    console.log("Elements chargé :", elements);
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      toast({
        title: "Erreur",
        description: "Le système de paiement n'est pas prêt. Veuillez réessayer plus tard.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("CardElement n'est pas disponible.");
      return;
    }

    try {
      console.log("Création du PaymentIntent...");

      // Vérifiez le montant envoyé
    const amountInCents = Math.round(totalPrice * 100);
    console.log(`Montant calculé à envoyer au backend (centimes) : ${amountInCents}`);

      const response = await fetch(`${apiUrl}/api/stripe/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': 'DENY',
          'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
        },
        credentials: 'include', // Inclus les cookies si nécessaire
        body: JSON.stringify({ amount: Math.round(totalPrice * 100) }), // Stripe utilise les centimes
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Erreur lors de la création du PaymentIntent :", errorText);
        toast({
          title: "Erreur",
          description: "Impossible de créer le paiement. Veuillez réessayer.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const { clientSecret } = await response.json();

      if (!clientSecret) {
        console.error("ClientSecret manquant.");
        return;
      }

      console.log("Confirmation du paiement...");
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });

      if (result.error) {
        console.error("Erreur Stripe :", result.error.message);
        toast({
          title: "Erreur de paiement",
          description: result.error.message || "Un problème est survenu.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (result.paymentIntent?.status === 'succeeded') {
        console.log("Paiement réussi :", result.paymentIntent);

        const numericKeyCount = parseInt(keyCount, 10); // Convertir en nombre entier
        const bonusKeys = calculateBonusKeys(numericKeyCount); // Calculez les clés bonus
        const totalKeys = numericKeyCount + bonusKeys; // Total des clés achetées + bonus

        // Enregistrer l'achat dans le backend
      await savePurchaseToBackend(numericKeyCount, totalPrice);

        toast({
          title: "Paiement réussi !",
          description: `Vous avez acheté ${numericKeyCount} clé(s) et reçu ${bonusKeys} clé(s) bonus, soit un total de ${totalKeys} clé(s).`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await updateUserKeys();
        // Redirection vers la homepage après un délai
        setTimeout(() => {
          window.location.href = "/";
        }, 5000); // Redirection après 5 secondes (durée du toast)
      }
    } catch (error) {
      console.error("Erreur inattendue :", error);
      toast({
        title: "Erreur",
        description: "Une erreur inattendue s'est produite. Veuillez réessayer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateUserKeys = async () => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("Identifiant utilisateur manquant.");
        return;
      }

      const numericKeyCount = parseInt(keyCount, 10) || 0; // Conversion ici
      const bonusKeys = calculateBonusKeys(numericKeyCount); // Calcul des clés bonus
      const totalKeys = numericKeyCount + bonusKeys; // Total des clés

      const response = await fetch(`${apiUrl}/api/user/credit-keys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          keysPurchased: totalKeys, // Envoyer le total des clés (achetées + bonus)
        }),
      });

      if (response.ok) {
        console.log("Les clés ont été créditées avec succès.");
      } else {
        const errorText = await response.text();
        console.error("Erreur lors du crédit des clés :", errorText);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des clés :", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Conteneur pour appliquer les styles visuels */}
      <Box bg="gray.700" p="10px" borderRadius="md">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#ffffff', // Texte blanc
                '::placeholder': { color: '#bbbbbb' }, // Placeholder gris clair
              },
              invalid: { color: '#ff4d4f' }, // Texte rouge pour erreur
            },
          }}
        />
      </Box>
      
      <Button
      mt={4}
      type="submit"
      isDisabled={!stripe || (inconsistencyDetected && verificationStatus !== "validated") || verificationStatus === "pending"}
      colorScheme="green"
       w="100%"
      >
       Acheter maintenant pour {totalPrice.toFixed(2)} €
      </Button>

      <Flex align="center" justify="center" mt={4} color="gray.300">
        <FaLock style={{ marginRight: '5px' }} /> Paiement sécurisé avec Stripe
      </Flex>
    </form>
  );
}

function BuyKeys() {
  const toast = useToast();
  const [keyCount, setKeyCount] = useState(""); // Nombre de clés achetées
  const [totalPrice, setTotalPrice] = useState(0); // Prix total
  const [userCountry, setUserCountry] = useState(null); // Pays de l'utilisateur (profil)
  const [ipCountry, setIpCountry] = useState(null); // Pays détecté par IP
  const [finalCountry, setFinalCountry] = useState(null); // Pays prioritaire (IP ou profil)
  const [vatRate, setVatRate] = useState(0); // Taux de TVA
  const apiUrl = process.env.REACT_APP_API_URL; // URL de l'API
  const userId = localStorage.getItem("userId"); // ID utilisateur
  const [inconsistencyDetected, setInconsistencyDetected] = useState(false); // Suivi des incohérences
  const [verificationStatus, setVerificationStatus] = useState(null); // Statut de la vérification


   // Récupérer le pays à partir des données utilisateur
  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/user/country/${userId}`);
        if (response.ok) {
          const { country } = await response.json();
          console.log("Pays du profil utilisateur :", country);
          setUserCountry(country);
        } else {
          console.error("Erreur lors de la récupération du pays utilisateur.");
        }
      } catch (error) {
        console.error("Erreur serveur lors de la récupération du pays utilisateur :", error);
      }
    };

    if (userId) fetchUserCountry();
  }, [userId, apiUrl]);

  // Récupérer la géolocalisation par adresse IP
  useEffect(() => {
    const fetchIpGeoInfo = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/user/ip-info/${userId}`);
        if (response.ok) {
          const { geoLocation } = await response.json();
          const countryName = isoToCountryName[geoLocation.country] || geoLocation.country;
          console.log("Pays détecté par IP :", countryName);
          setIpCountry(countryName);
        } else {
          console.error("Erreur lors de la récupération des informations IP.");
        }
      } catch (error) {
        console.error("Erreur serveur lors de la récupération des informations IP :", error);
      }
    };

    if (userId) fetchIpGeoInfo();
  }, [userId, apiUrl]);

  // Croiser les données pour détecter le pays final et appliquer la logique
  useEffect(() => {
    if (ipCountry && userCountry) {
      if (ipCountry !== userCountry) {
        console.warn(
          `Incohérence détectée : Pays IP (${ipCountry}) différent du pays utilisateur (${userCountry}).`
        );
        setInconsistencyDetected(true); // Marque l'incohérence
      } else {
        setInconsistencyDetected(false); // Aucune incohérence
        setFinalCountry(userCountry); // Définit le pays final si tout est correct
        setVatRate(vatRates[userCountry] || 0); // Mets à jour la TVA
      }
    }
  }, [ipCountry, userCountry]);
  
  


useEffect(() => {
  const numericKeyCount = parseInt(keyCount, 10) || 0; // Convertit keyCount en nombre ou 0 par défaut
  console.log("Nombre de clés détecté (keyCount) :", numericKeyCount);

  // Vérification du taux de TVA
  if (isNaN(vatRate)) {
    console.error("Le taux de TVA est invalide :", vatRate);
    return; // Ne pas continuer si vatRate n'est pas valide
  }

  // Calculer le prix sans réduction mais avec TVA
  const priceBeforeVat = numericKeyCount * 1; // Prix unitaire de la clé
  const priceWithVat = Math.round(priceBeforeVat * (1 + vatRate / 100) * 100) / 100;

  setTotalPrice(priceWithVat); // Mettre à jour le prix total
  console.log(`Prix final calculé avec TVA : ${priceWithVat}`);
}, [keyCount, vatRate]);



  const handleKeyCountChange = (e) => {
    const count = e.target.value; // Récupère la valeur brute (chaîne)
    console.log("Changement de nombre de clés saisi :", count); // Ajout
    if (count === "" || /^\d+$/.test(count)) { // Accepte une chaîne vide ou des nombres
      setKeyCount(count);
    } else {
      console.warn("Valeur non valide pour keyCount :", count); // Log si une valeur invalide est détectée
    }
  };

  const handleResidenceConfirmation = async () => {
    try {
      setVerificationStatus("pending"); // Démarre la vérification
  
      const response = await fetch(`${apiUrl}/api/user/verify-residence`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, declaredCountry: userCountry }),
      });
  
      if (response.ok) {
        setVerificationStatus("validated"); // Vérification réussie
        toast({
          title: "Résidence confirmée.",
          description: `Le taux de TVA du pays déclaré (${userCountry}) sera appliqué.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFinalCountry(userCountry); // Utilise le pays déclaré
        setVatRate(vatRates[userCountry] || 0); // Mets à jour la TVA
      } else {
        setVerificationStatus("rejected"); // Échec de la vérification
        const { message } = await response.json();
        toast({
          title: "Échec de la vérification.",
          description: message || "Nous n'avons pas pu confirmer votre résidence.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de la résidence :", error);
      toast({
        title: "Erreur.",
        description: "Une erreur est survenue lors de la vérification.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  


  return (

    <Box mt={10} p={5} maxW="500px" mx="auto" bg="gray.800" borderRadius="lg" boxShadow="lg">
    {/* Texte stylisé */}
    <Flex justify="center" mb={5}>
      <Heading
        sx={{
          background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          transition: 'transform 0.2s',
          cursor: 'pointer',
          _hover: { transform: 'scale(1.1)' },
        }}
        onClick={() => window.location.reload()}
      >
        Onlybox
      </Heading>
    </Flex>

    <Box mt={10} p={5} maxW="500px" mx="auto" bg="gray.800" borderRadius="lg">
      <Heading as="h3" size="lg" mb={5} color="white">Acheter des clés</Heading>
      {userCountry && (
          <Text color="white" mb={3}>
            Votre pays détecté : {finalCountry || "Non détecté"} (TVA : {vatRate}%)
          </Text>
        )}
      <Text mb={3} color="white">Nombre de clés :</Text>
      <Input
        type="text" // Change à "text" pour accepter une chaîne vide
        value={keyCount}
        onChange={handleKeyCountChange}
        mb={5}
        color="white"
        borderColor="gray.600"
        _placeholder={{ color: "gray.400" }}
        _hover={{ borderColor: "gray.500" }}
        bg="gray.700"
        placeholder="Entrez un nombre" // Ajoute un placeholder
      />
     <Box mb={5} p={3} bg="gray.700" borderRadius="md">
  {/* Affichage des seuils et des bonus */}
  <Text color="white">1 clé = 1,00 €</Text>
  <Text color="white">Achetez 5 clés, obtenez 1 clé bonus !</Text>
  <Text color="white">Achetez 10 clés, obtenez 2 clés bonus !</Text>
  </Box>

  {/* Section pour afficher les prix et le total des clés */}
  <Text color="white">
  Total payé (TVA incluse) : {totalPrice.toFixed(2)} €
  </Text>
  <Text color="white">
  Nombre total de clés (incluant bonus) : {parseInt(keyCount, 10) + calculateBonusKeys(parseInt(keyCount, 10))}
  </Text>
  <Text color="white">
  Vous avez acheté {keyCount} clés et recevrez {calculateBonusKeys(parseInt(keyCount, 10))} clés bonus.
  </Text>


  {inconsistencyDetected && (
  <Box mt={5} bg="gray.700" p={5} borderRadius="md">
    <Text color="yellow.400" fontWeight="bold">
      Une incohérence a été détectée entre votre pays déclaré ({userCountry}) et votre localisation actuelle ({ipCountry}).
    </Text>
    {verificationStatus === null && (
      <>
        <Button mt={3} colorScheme="blue" onClick={handleResidenceConfirmation}>
          Confirmer mon pays de résidence : {userCountry}
        </Button>
        <Text mt={3} color="gray.300">
          En cas de confirmation, le taux de TVA de votre pays déclaré sera appliqué.
        </Text>
      </>
    )}
    {verificationStatus === "pending" && (
      <Text mt={3} color="gray.300">
        Vérification en cours...
      </Text>
    )}
    {verificationStatus === "validated" && (
      <Text mt={3} color="green.300">
        Résidence confirmée avec succès. Le taux de TVA de votre pays déclaré ({userCountry}) sera appliqué.
      </Text>
    )}
    {verificationStatus === "rejected" && (
      <Text mt={3} color="red.300">
        Échec de la vérification. Contactez le support pour plus d'informations.
      </Text>
    )}
  </Box>
)}
      
    {/* Section pour afficher les prix avec plus de clarté */}
    <Text color="white">Prix final avec TVA : {totalPrice.toFixed(2)} €</Text>

     {/* Affiche le message d'avertissement avant le formulaire si nécessaire */}
    {inconsistencyDetected && verificationStatus !== "validated" && (
    <Text color="yellow.300" mt={3}>
    Veuillez confirmer votre pays de résidence pour continuer votre achat.
  </Text>
    )}

      {stripePromise && (
        <Elements stripe={stripePromise}>
        <CheckoutForm
          totalPrice={totalPrice}
          keyCount={keyCount}
          inconsistencyDetected={inconsistencyDetected}
          verificationStatus={verificationStatus}
          ipCountry={ipCountry} // Passez ipCountry ici
        />
      </Elements>
      )}

      {/* Section FAQ */}
      <Box mt={10} p={5} bg="gray.900" borderRadius="lg">
        <Heading as="h4" size="md" color="white" mb={3}>FAQ - Questions fréquentes</Heading>
        <Text color="white" mb={2}><strong>Comment utiliser les clés ?</strong></Text>
        <Text color="gray.300" mb={3}>Les clés servent à ouvrir des caisses contenant des récompenses.</Text>
        <Text color="white" mb={2}><strong>Les clés expirent-elles ?</strong></Text>
        <Text color="gray.300">Non, vos clés sont valides à vie.</Text>
      </Box>

      {/* Témoignages */}
      <Box mt={5} p={5} bg="gray.900" borderRadius="lg">
        <Heading as="h4" size="md" color="white" mb={3}>Témoignages clients ⭐</Heading>
        <Text color="gray.300">"Achat rapide et sécurisé, je recommande !" - <strong>Utilisateur A</strong></Text>
        <Text color="gray.300">"J'ai obtenu des super récompenses avec mes clés !" - <strong>Utilisateur B</strong></Text>
      </Box>

      {/* Mentions légales */}
      <Text color="gray.400" mt={5} fontSize="sm" textAlign="center">
        En continuant, vous acceptez nos <a href="/cgu" style={{ color: '#90cdf4' }}>Conditions générales d'utilisation</a> et notre{' '}
        <a href="/rgpd" style={{ color: '#90cdf4' }}>Politique de confidentialité</a>.
      </Text>
    </Box>
    </Box>
  );
}

export default BuyKeys;