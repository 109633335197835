import React, { useState } from 'react';
import { Box, HStack, Image, VStack, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const BoxMediaPreview = ({ mediaFiles, rarityRates, getBlurredImagePath, getRarityBadge, handleImageClick }) => {
  // État pour gérer l'index de l'image survolée
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);

  // Divisez les médias en deux groupes
  const firstRowMedia = mediaFiles.slice(0, 6);
  const secondRowMedia = mediaFiles.slice(6, 12);

  return (
    <Box mt={12}>
      <Text fontSize="lg" mb={4}>Médias dans cette caisse :</Text>
      
      {/* Première ligne de médias */}
      <HStack spacing={4}>
        {firstRowMedia.map((media, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onMouseEnter={() => setHoveredImageIndex(index)}
            onMouseLeave={() => setHoveredImageIndex(null)}
            style={{ position: 'relative' }}
          >
            <Image
              height="100px"
              width="90px"
              objectFit="cover"
              borderRadius="md"
              src={getBlurredImagePath(media.filename)}
              alt={`aperçu-${index}`}
              onContextMenu={handleImageClick}
              onClick={handleImageClick}
              draggable="false"
            />
            {hoveredImageIndex === index && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.85 }}
                exit={{ opacity: 0 }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  padding: '6px',
                  fontSize: '11px',
                  textAlign: 'center',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                }}
              >
                <VStack spacing={1}>
                  {getRarityBadge(media.rarity)}
                  <Text>{rarityRates[media.rarity]}% loot chance</Text>
                </VStack>
              </motion.div>
            )}
          </motion.div>
        ))}
      </HStack>

      {/* Deuxième ligne de médias */}
      <HStack spacing={4} mt={4}>
        {secondRowMedia.map((media, index) => (
          <motion.div
            key={index + 6} // Décalage de l'index pour la deuxième ligne
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onMouseEnter={() => setHoveredImageIndex(index + 6)}
            onMouseLeave={() => setHoveredImageIndex(null)}
            style={{ position: 'relative' }}
          >
            <Image
              height="100px"
              width="90px"
              objectFit="cover"
              borderRadius="md"
              src={getBlurredImagePath(media.filename)}
              alt={`aperçu-${index + 6}`}
              onContextMenu={handleImageClick}
              onClick={handleImageClick}
              draggable="false"
            />
            {hoveredImageIndex === index + 6 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.85 }}
                exit={{ opacity: 0 }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  padding: '6px',
                  fontSize: '11px',
                  textAlign: 'center',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                }}
              >
                <VStack spacing={1}>
                  {getRarityBadge(media.rarity)}
                  <Text>{rarityRates[media.rarity]}% loot chance</Text>
                </VStack>
              </motion.div>
            )}
          </motion.div>
        ))}
      </HStack>
    </Box>
  );
};

export default BoxMediaPreview;