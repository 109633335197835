//pour afficher la liste des carrousels.

import React from "react";
import {
  List,
  ListItem,
  Box,
  Heading,
  SimpleGrid,
  Image,
  Button,
  Text,
} from "@chakra-ui/react";

const CarouselList = ({ carouselData, handleDeleteCarouselImage, handleDeleteCarousel }) => {
  return (
    <>
      <Heading mt={10} mb={5}>
        Carrousels Créés
      </Heading>
      <List spacing={4}>
        {carouselData.length > 0 ? (
          carouselData.map((carousel) => (
            <ListItem key={carousel._id}>
              <Box p={3} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="sm">Carrousel {carousel._id}</Heading>
                <SimpleGrid columns={[2, null, 4]} spacing={4} mt={2}>
                  {carousel.images.map((image, index) => (
                    <Box key={index} position="relative">
                      {/* Affiche l'image */}
                      <Image
                        src={`${process.env.REACT_APP_API_URL}/uploads/${image}`}
                        alt={`Carousel Image ${index + 1}`}
                        boxSize="100px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                      {/* Bouton pour supprimer une image spécifique */}
                      <Button
                        size="xs"
                        colorScheme="red"
                        position="absolute"
                        top="0"
                        right="0"
                        onClick={() => handleDeleteCarouselImage(carousel._id, index)} // Passe l'ID du carrousel et l'index de l'image
                      >
                        X
                      </Button>
                    </Box>
                  ))}
                </SimpleGrid>
                {/* Bouton pour supprimer le carrousel entier */}
                <Button
                  colorScheme="red"
                  mt={3}
                  onClick={() => handleDeleteCarousel(carousel._id)}
                >
                  Supprimer Carrousel
                </Button>
              </Box>
            </ListItem>
          ))
        ) : (
          <Text>Aucun carrousel trouvé.</Text>
        )}
      </List>
    </>
  );
};

export default CarouselList;