import React, { useState } from 'react';
import { Box, Button, Collapse, Heading, SimpleGrid, Image, Text } from "@chakra-ui/react";

const CollapsibleBox = ({ box, handleDeleteBox }) => {
  const [isOpen, setIsOpen] = useState(false); // État pour contrôler l'ouverture du bloc

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box p={3} shadow="md" borderWidth="1px" borderRadius="md">
      {/* Titre du bloc */}
      <Heading size="sm" onClick={toggleCollapse} cursor="pointer">
        {box.title} {isOpen ? "▲" : "▼"}
      </Heading>

      {/* Contenu du bloc : collapsible */}
      <Collapse in={isOpen} animateOpacity>
        <Box mt={2}>
          {/* Afficher le modèle */}
          <Text>
            <strong>Modèle :</strong> {box.createdByModelId?.username || 'Non attribué'}
          </Text>
          
          {/* Liste des médias */}
          <strong>Médias:</strong>
          <SimpleGrid columns={[2, null, 4]} spacing={4} mt={2}>
            {box.mediaFiles.map((file) => (
              <Image
                key={file.filename}
                src={`${process.env.REACT_APP_API_URL}/uploads/${file.filename}`}
                alt="Media"
                boxSize="100px"
                objectFit="cover"
                borderRadius="md"
              />
            ))}
          </SimpleGrid>
        </Box>
        {/* Bouton de suppression */}
        <Button colorScheme="red" mt={3} onClick={() => handleDeleteBox(box._id)}>
          Supprimer
        </Button>
      </Collapse>
    </Box>
  );
};

export default CollapsibleBox;
