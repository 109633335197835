import React, { useEffect } from "react";

const CookiesPolicyPage = () => {
  useEffect(() => {
    document.title = "Politique d’utilisation des cookies - Onlybox";
  }, []);

  return (
    <div className="cookies-policy-body">
      <h1>Politique d’utilisation des cookies</h1>
      <p>
        Voici les informations concernant l’utilisation des cookies sur notre site.
        Nous utilisons des cookies essentiels pour le bon fonctionnement du site, et des cookies optionnels pour améliorer votre expérience.
      </p>
    </div>
  );
};

export default CookiesPolicyPage;
