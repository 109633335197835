//Ce composant gère le menu hamburger pour les utilisateurs non connectés.

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
  } from '@chakra-ui/react';
  import { HamburgerIcon } from '@chakra-ui/icons';
  
  const MenuHamburger = ({ navigate }) => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<HamburgerIcon />}
          variant="outline"
          aria-label="Options"
          size="lg"
          color="white"
          backgroundColor="transparent"
          _hover={{ bg: 'gray.600' }}
          _active={{ bg: 'gray.700' }}
        />
        <MenuList bg="black" border="1px solid black" boxShadow="lg" borderRadius="md">
          <MenuItem onClick={() => navigate('/login')} _hover={{ bg: 'black', color: 'gray.700' }}>
            Se connecter
          </MenuItem>
          <MenuItem onClick={() => navigate('/signup')} _hover={{ bg: 'black', color: 'gray.700' }}>
            S'inscrire
          </MenuItem>
          <MenuItem onClick={() => navigate('/model/login')} _hover={{ bg: 'black', color: '#FF007F' }}>
            Se connecter (Modèle)
          </MenuItem>
          <MenuItem onClick={() => navigate('/model/signup')} _hover={{ bg: 'black', color: '#FF007F' }}>
            S'inscrire (Modèle)
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };
  
  export default MenuHamburger;
  