import React from "react";
import { Box, Flex, Heading, Image, Text, Tooltip, Button  } from "@chakra-ui/react"; // Si tu utilises Chakra UI
import { Link } from "react-router-dom"; // Si tu utilises React Router
import { AiFillHeart, AiOutlineHeart, AiOutlineCheckCircle } from "react-icons/ai"; // Import des icônes
// Assure-toi que `categories`, `filteredBoxes`, `apiUrl`, `likes` et `toggleLike` soient passés correctement comme props


// Composant CategoryBoxes
const CategoryBoxes = ({ categories, filteredBoxes, apiUrl, likes, toggleLike, suggestions }) => {
  return (
    <Box className="categories" padding="0">
      {categories.map((category) => (
        <>
          {/* Déplacer le titre (Heading) à l'extérieur */}
          <Heading
            as="h2"
            size="lg"
            mb={3}
            textAlign="left"
            className="category-title"
          >
            {category}
          </Heading>
        
        
        {/* Conteneur de la section */}
        <Box key={category} className="category-section" mb={5}>
          <Flex
            className="boxes"
            id={`category-carousel-${category}`}
            gap={3}
            wrap="nowrap"
            overflowX="auto"
            paddingX={2}
          >
            {filteredBoxes
              .filter((box) => box.category === category)
              .map((box) => (
                <Link
                  to={`/box/${box._id}`}
                  key={box._id}
                  className="box"
                  style={{ marginBottom: "20px" }}
                >
                  <Image
                    src={`${apiUrl}/uploads/${box.thumbnail}`}
                    alt={`${box.title} thumbnail`}
                    className="box-thumbnail"
                    width="120px"
                    height="100px"
                    objectFit="cover"
                    borderRadius="md"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  />
                  <Heading as="h3" size="md">
                    {box.title}
                  </Heading>

                  {/* Badge pour indiquer le type de box */}
                  <Flex alignItems="center" mt={2} className="box-type-indicator">
                    {box.boxType === "photo" && (
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color="blue.400" // Change la couleur pour "Photos uniquement"
                        border="1px solid"
                        borderColor="blue.400"
                        borderRadius="md"
                        padding="2px 8px"
                      >
                        📷 Photos uniquement
                      </Text>
                    )}
                    {box.boxType === "video" && (
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color="orange.400" // Change la couleur pour "Mixte (Photos & Vidéos)"
                        border="1px solid"
                        borderColor="pink.400"
                        borderRadius="md"
                        padding="2px 8px"
                      >
                        🎥 Mixte (Photos & Vidéos)
                      </Text>
                    )}
                  </Flex>

                  <Text>Prix TTC : {parseFloat(box.price).toFixed(2)}€</Text>

                  {/* Indicateur pour montrer que la boîte est déjà ouverte */}
                  {box.alreadyOpened && (
                    <Tooltip label="Déjà ouvert" fontSize="sm" hasArrow>
                      <Flex alignItems="center" mt={2} className="indicator">
                        <AiOutlineCheckCircle color="green" size={24} />
                        <Text
                          ml={2}
                          fontSize="sm"
                          color="green.400"
                          display={{ base: "block", md: "none" }}
                        >
                          Déjà ouvert
                        </Text>
                      </Flex>
                    </Tooltip>
                  )}

                  <Flex alignItems="center" mt={2}>
                    {likes[box._id] ? (
                      <AiFillHeart
                        color="red"
                        size={24}
                        cursor="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleLike(box._id);
                        }}
                      />
                    ) : (
                      <AiOutlineHeart
                        size={24}
                        cursor="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleLike(box._id);
                        }}
                      />
                    )}
                    <Text ml={2} fontSize="md" color="white">
                      {box.likes || 0}
                    </Text>
                  </Flex>
                </Link>
              ))}
          </Flex>
        </Box>
        </>
      ))}

      {/* Section pour les suggestions */}
{suggestions && suggestions.length > 0 && (
  <>
    <Heading
      as="h2"
      size="lg"
      mb={3}
      textAlign="left"
      className="category-title"
    >
      Suggestions
    </Heading>
    <Box className="suggestions-section" mb={5}>
      <Flex
        className="boxes"
        gap={3}
        wrap="nowrap"
        overflowX="auto"
        paddingX={2}
      >
        {suggestions.map((suggestion) => {
          const box = suggestion.boxId; // Facilite la lecture
          if (!box) {
            return null; // Ignore les suggestions sans `boxId`
          }

          return (
            <Link
              to={`/box/${box._id}`}
              key={box._id}
              className="box"
              style={{ marginBottom: "20px" }}
            >
              {/* Image principale */}
              <Image
                src={box.thumbnail ? `${apiUrl}/uploads/${box.thumbnail}` : "/default-thumbnail.png"}
                alt={box.title || "Image introuvable"}
                className="box-thumbnail"
                width="120px"
                height="100px"
                objectFit="cover"
                borderRadius="md"
              />

              {/* Titre */}
              <Heading as="h3" size="md">
                {box.title || "Titre introuvable"}
              </Heading>

              {/* Badge pour le type de box */}
              <Flex alignItems="center" mt={2} className="box-type-indicator">
                {box.boxType === "photo" && (
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="blue.400" // Change la couleur pour "Photos uniquement"
                    border="1px solid"
                    borderColor="blue.400"
                    borderRadius="md"
                    padding="2px 8px"
                  >
                    📷 Photos uniquement
                  </Text>
                )}
                {box.boxType === "video" && (
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="orange.400" // Change la couleur pour "Mixte (Photos & Vidéos)"
                    border="1px solid"
                    borderColor="pink.400"
                    borderRadius="md"
                    padding="2px 8px"
                  >
                    🎥 Mixte (Photos & Vidéos)
                  </Text>
                )}
              </Flex>

              {/* Prix */}
              <Text>
                Prix TTC : {box.price ? parseFloat(box.price).toFixed(2) : "Non disponible"}€
              </Text>

              {/* Indicateur pour montrer que la boîte est déjà ouverte */}
              {box.alreadyOpened && (
                <Tooltip label="Déjà ouvert" fontSize="sm" hasArrow>
                  <Flex alignItems="center" mt={2} className="indicator">
                    <AiOutlineCheckCircle color="green" size={24} />
                    <Text
                      ml={2}
                      fontSize="sm"
                      color="green.400"
                      display={{ base: "block", md: "none" }}
                    >
                      Déjà ouvert
                    </Text>
                  </Flex>
                </Tooltip>
              )}

              {/* Section des likes */}
              <Flex alignItems="center" mt={2}>
                {likes[box._id] ? (
                  <AiFillHeart
                    color="red"
                    size={24}
                    cursor="pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleLike(box._id);
                    }}
                  />
                ) : (
                  <AiOutlineHeart
                    size={24}
                    cursor="pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleLike(box._id);
                    }}
                  />
                )}
                <Text ml={2} fontSize="md" color="white">
                  {box.likes || 0}
                </Text>
              </Flex>

              {/* Bouton "Découvrir" */}
              <Button
                colorScheme="blue"
                size="sm"
                mt={3}
                onClick={(e) => {
                  e.preventDefault(); 
                  e.stopPropagation();
                  window.location.href = `/box/${box._id}`;
                }}
              >
                Découvrir
              </Button>
            </Link>
          );
        })}
      </Flex>
    </Box>
  </>
)}
</Box>
  );
};

export default CategoryBoxes;