import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ChakraProvider, Box, Flex, useToast } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import store from './store';
import './App.css';


import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import BoxDetailPage from './pages/BoxDetailPage';
import SignupPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import InventoryPage from './pages/InventoryPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import BuyKeys from './pages/BuyKeys';
import ForgotPasswordPageUser from './pages/ForgotPasswordPageUser';
import ResetPasswordPageUser from './pages/ResetPasswordPageUser';
import ModelSignupPage from './pages/ModelSignupPage';
import ModelLoginPage from './pages/ModelLoginPage';
import ModelProfitPage from './pages/ModelProfitPage';
import SettingsPage from './pages/SettingsPage';
import UserProfile from './pages/UserProfile';
import SettingsPagemodel from './pages/SettingsPagemodel';
import ModelProfile from './pages/ModelProfile';


// Nouvelle importation pour la confirmation d'email et modèle
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import EmailConfirmationSentPage from './pages/EmailConfirmationSentPage'; // Ajouter cette ligne au début
import ConfirmEmailModelPage from './pages/ConfirmEmailModelPage'; // Ajout pour les modèles
import EmailModelConfirmationSentPage from './pages/EmailModelConfirmationSentPage'; // Ajout pour les modèles


// Import de la nouvelle page AgeVerification
import AgeVerification from './pages/AgeVerification';

// Import de la nouvelle page Cookie
import CookieConsent from "./components/CookieConsent";

// Import de la nouvelle page PrivacyPolicyPage/CookiesPolicyPage
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiesPolicyPage from './pages/CookiesPolicyPage';




// Animation des cercles qui tournent
const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const bounceAnimation = keyframes`
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(0.75); }
`;

// Composant pour les cercles en rotation
const CircleSpinner = () => {
  const circles = [...Array(8)]; // Création de 8 cercles
  const circleSize = 8; // Taille plus petite des cercles
  const radius = 25; // Rayon plus petit pour un spinner compact

  return (
    <Flex
      position="relative"
      width={`${radius * 2}px`}
      height={`${radius * 2}px`}
      justifyContent="center"
      alignItems="center"
      animation={`${spinnerAnimation} 1.5s linear infinite`} // Animation pour la rotation complète
    >
      {circles.map((_, i) => (
        <Box
          key={i}
          position="absolute"
          width={`${circleSize}px`}
          height={`${circleSize}px`}
          borderRadius="50%"
          bg="black"
          transform={`rotate(${i * 45}deg) translateX(${radius}px)`} // translateX pour déplacer les cercles autour du centre
          animation={`${bounceAnimation} 1.5s ease-in-out ${i * 0.1}s infinite`} // Animation du rebond des cercles
        />
      ))}
    </Flex>
  );
};

// Composant qui gère le chargement en fonction du changement de route
function LoadingWrapper({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true); // Activer le Spinner lors du changement de route
    const timer = setTimeout(() => {
      setIsLoading(false); // Désactiver après 1 seconde
    }, 1000);

    return () => clearTimeout(timer); // Nettoyer après chaque changement de route
  }, [location]);

    // Mise à jour de la classe du <body> en fonction de la route
  useEffect(() => {
    if (location.pathname === "/cookies-policy") {
      document.body.className = "cookies-policy-body";
    } else if (location.pathname === "/privacy-policy") {
      document.body.className = "privacy-policy-body";
    } else {
      document.body.className = ""; // Classe par défaut
    }
  }, [location.pathname]); // Dépendance correcte pour déclencher l'effet à chaque changement de route

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircleSpinner />
      </Box>
    );
  }

  return children; // Affichage du contenu après le chargement
}

function App() {
  const toast = useToast();
  const [isAdultConfirmed, setIsAdultConfirmed] = useState(false);


  // Vérifie la confirmation d'âge dans le localStorage
  useEffect(() => {
    const adultConfirmed = localStorage.getItem('isAdultConfirmed') === 'true';
    setIsAdultConfirmed(adultConfirmed);
  }, []);

  // Gestion de la connexion Internet
  useEffect(() => {
    const handleOffline = () => {
      console.log("Connexion Internet perdue ❌");
      toast({
        title: "Connexion perdue",
        description: "Vous avez perdu la connexion Internet.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    };

    const handleOnline = () => {
      console.log("Connexion Internet rétablie ✅");
      toast({
        title: "Connexion rétablie",
        description: "Votre connexion Internet a été rétablie.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [toast]);


  return (
    <Provider store={store}>
      <ChakraProvider>
        {/* Ajout de la bannière de consentement */}
        <CookieConsent />
        <Router>
          <LoadingWrapper>
          <Routes>
              {/* Si l'âge n'est pas confirmé, redirige toutes les routes vers /age-verification */}
              {!isAdultConfirmed ? (
                <Route path="*" element={<Navigate to="/age-verification" replace />} />
              ) : (
                <>
              <Route path="/" element={<HomePage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/box/:id" element={<BoxDetailPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/inventory" element={<InventoryPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
              <Route path="/forgot-password-user" element={<ForgotPasswordPageUser />} />
              <Route path="/reset-password-user/:token" element={<ResetPasswordPageUser />} />

              {/* Routes pour les nouvelles pages */}
              <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

              {/* Routes pour la confirmation d'email */}
              <Route path="/confirm-email" element={<ConfirmEmailPage />} />  {/* Nouvelle route ajoutée */}
              {/* Nouvelle route pour confirmer que l'email a été envoyé */}
              <Route path="/email-confirmation-sent" element={<EmailConfirmationSentPage />} />

              {/* Routes pour la confirmation d'email des modèles */}
              <Route path="/confirm-model-email" element={<ConfirmEmailModelPage />} />
              <Route path="/email-model-confirmation-sent" element={<EmailModelConfirmationSentPage />} /> {/* Ajout */}


              {/* Routes paramètres pour les utilisateurs */}
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/user-profile" element={<UserProfile />} />

              {/* Routes pour les paramètres et le profil des modèles */}
              <Route path="/model/settings" element={<SettingsPagemodel />} />
              <Route path="/model/profile" element={<ModelProfile />} />

              {/* Routes pour les modèles photo */}
              <Route path="/model/signup" element={<ModelSignupPage />} />
              <Route path="/model/login" element={<ModelLoginPage />} />
              <Route path="/model/profit/:modelId" element={<ModelProfitPage />} />
              

              

              {/* Routes pour l'achat des BuyKeys */}
              <Route path="/buy-keys" element={<BuyKeys />} />
              </>
              )}
            {/* Route pour la page de vérification d'âge */}
            <Route path="/age-verification" element={<AgeVerification />} />

          
            
            </Routes>
          </LoadingWrapper>
        </Router>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
