import React, { useState, useRef } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';

const BoxHeader = ({ title, thumbnail, box, showFullDescription, setShowFullDescription, onInfoClick }) => {
  const [hovered, setHovered] = useState(false);
  const [touched, setTouched] = useState(false); // Gérer les appareils tactiles

  const containerRef = useRef(null); // Référence pour le conteneur de la description

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);

    // Réinitialiser le défilement au début lorsque la description est étendue
    if (!showFullDescription && containerRef.current) {
      containerRef.current.scrollTop = 0; // Déplace le conteneur au début
    }
  };

  const handleTouch = () => {
    setTouched(!touched);
  };

  return (
    <Box textAlign="center" my={4} className="box-item">
      {/* Conteneur du titre et de l'icône */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Text
          className="box-title"
          fontSize="3xl"
          fontWeight="bold"
          overflow="hidden"
          whiteSpace="nowrap"
          maxWidth="200px"
          mr={2} // Ajout d'un espace entre le texte et l'icône
        >
          {title}
        </Text>
        <FaInfoCircle
          onClick={onInfoClick}
          style={{
            cursor: 'pointer',
            fontSize: '20px', // Taille de l'icône
          }}
        />
      </Box>


      {/* Conteneur de l'image */}
      <Box 
        className="box-image-container"
        my={4}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onTouchStart={handleTouch} // Activer au toucher
      >
        <Image
          height="150px"
          width="200px"
          objectFit="cover"
          src={thumbnail}
          alt={`${title} thumbnail`}
          mx="auto"
        />
        {/* Afficher les informations uniquement lorsque la box est survolée ou touchée */}
        {(hovered || touched) && (
          <div
            className={`box-info-hover ${showFullDescription ? 'expanded' : ''}`}
            ref={containerRef} // Attacher la référence au conteneur
          >
            <p>
              {showFullDescription ? box.description : `${box.description.slice(0, 50)}...`}
              <span 
                onClick={handleToggleDescription} 
                className="more"
                style={{ color: '#FFD700', cursor: 'pointer' }}
              >
                {showFullDescription ? ' Voir moins' : ' Voir plus'}
              </span>
            </p>
            <p>Prix: €{box.price}</p>
            <p>Catégorie: {box.category}</p>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default BoxHeader;

