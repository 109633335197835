import React from "react";
import { List, ListItem, Box, Heading, Image, Text } from "@chakra-ui/react";

const StoryList = ({ stories }) => {
  return (
    <>
      <Heading mt={10} mb={5} size="md">Stories Créées</Heading>
      <List spacing={4}>
        {stories.length > 0 ? (
          stories.map((story) => (
            <ListItem key={story._id}>
              <Box p={3} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="sm">{story.title}</Heading>
                <Box mt={2}>
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/uploads/${story.avatar}`}
                    alt="Avatar"
                    boxSize="50px"
                    objectFit="cover"
                    borderRadius="full"
                  />
                  <Text mt={2}>
                    <strong>Créateur:</strong> {story.creator}
                  </Text>
                </Box>
              </Box>
            </ListItem>
          ))
        ) : (
          <Text>Aucune story trouvée.</Text>
        )}
      </List>
    </>
  );
};

export default StoryList;
