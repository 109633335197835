import React from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  HStack,
  Box,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import twemoji from "twemoji";

const CreateBoxForm = ({
  models,
  selectedModel,
  setSelectedModel,
  selectedCountry,
  setSelectedCountry,
  TVA_RATES,
  price,
  setPrice,
  priceHT,
  commission,
  modelRevenue,
  title,
  setTitle,
  description,
  setDescription,
  category,
  setCategory,
  boxType,
  setBoxType,
  mediaFiles,
  mediaFilesRef,
  mediaRarities,
  handleMediaUpload,
  handleRarityChange,
  originalVideos,
  originalVideosRef,
  videoRarities,
  videoDropRates,
  handleVideoUpload,
  handleRarityChangeForVideo,
  thumbnailRef,
  handleThumbnailUpload,
  handleSubmitBox,
  calculateValues,
  emojis,
  showEmojiPicker,
  setShowEmojiPicker,

  // **NOUVEAUX ÉTATS ET HANDLERS**
  shortVideos, // Liste des extraits courts
  shortVideosRef, // Référence pour l'input des extraits courts
  handleShortVideoUpload, // Fonction pour uploader les extraits courts
  shortVideoRarities, // Tableau des raretés des extraits courts
  handleRarityChangeForShortVideos, // Fonction pour changer la rareté des extraits courts

}) => {
  return (
    <VStack as="form" spacing={4} onSubmit={handleSubmitBox}>
      {/* Sélectionner un modèle (non obligatoire) */}
      <FormControl>
        <FormLabel>Sélectionner un modèle (optionnel)</FormLabel>
        <Select
          value={selectedModel}
          onChange={(e) => {
            setSelectedModel(e.target.value);
            setSelectedCountry("FR"); // Réinitialise le pays à France par défaut
          }}
        >
          <option value="">-- Choisissez un modèle --</option>
          {models.map((model) => (
            <option key={model._id} value={model._id}>
              {model.username}
            </option>
          ))}
        </Select>
      </FormControl>

      {/* Sélectionner le pays de résidence */}
      <FormControl isDisabled={!selectedModel}>
        <FormLabel>Pays de résidence du modèle</FormLabel>
        <Select
          value={selectedCountry}
          onChange={(e) => {
            setSelectedCountry(e.target.value);
            calculateValues(price, e.target.value); // Recalcule les valeurs avec le pays
          }}
        >
          {Object.entries(TVA_RATES).map(([code, rate]) => (
            <option key={code} value={code}>
              {code} - {rate}%
            </option>
          ))}
        </Select>
      </FormControl>

      {/* Saisir le prix TTC */}
      <FormControl isRequired>
        <FormLabel>Prix TTC</FormLabel>
        <Input
          type="number"
          placeholder="Prix TTC"
          value={price}
          onChange={(e) => {
            setPrice(e.target.value);
            calculateValues(e.target.value, selectedCountry);
          }}
        />
      </FormControl>

      {/* Type de caisse */}
      <FormControl isRequired>
        <FormLabel>Type de Caisse</FormLabel>
        <Select value={boxType} onChange={(e) => setBoxType(e.target.value)}>
          <option value="photo">Photo</option>
          <option value="video">Vidéo</option>
        </Select>
      </FormControl>

      {/* Titre */}
      <FormControl isRequired>
        <FormLabel>Titre</FormLabel>
        <Input
          type="text"
          placeholder="Titre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormControl>

      {/* Description */}
      <FormControl isRequired>
        <FormLabel>Description</FormLabel>
        <Textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button mt={2} onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
          Ajouter un emoji 😊
        </Button>
        {showEmojiPicker && (
          <Box
            mt={2}
            maxH="200px"
            overflowY="scroll"
            border="1px solid #ccc"
            borderRadius="md"
            p={2}
          >
            <SimpleGrid columns={10} spacing={2}>
              {emojis.map((emoji, index) => (
                <Text
                  key={index}
                  fontSize="24px"
                  cursor="pointer"
                  dangerouslySetInnerHTML={{ __html: twemoji.parse(emoji) }}
                  onClick={() => {
                    setDescription((prev) => prev + emoji);
                    setShowEmojiPicker(false);
                  }}
                ></Text>
              ))}
            </SimpleGrid>
          </Box>
        )}
      </FormControl>
      <FormControl isRequired>
                <FormLabel>Prix TTC</FormLabel>
                <Input
                  type="number"
                  placeholder="Prix TTC"
                  value={price}
                  onChange={(e) => {setPrice(e.target.value);
                  calculateValues(e.target.value, selectedCountry); // Passe le code du pays sélectionné
                }}
                />
               </FormControl>
      
               {/* Affichage des valeurs calculées */}
               {price && (
                <Box mt={4}>
                <Text><strong>Prix HT :</strong> {priceHT} €</Text>
                {/* Si un modèle est sélectionné, afficher la commission et le revenu */}
                {selectedModel ? (
                  <>
                <Text><strong>Commission (40%) :</strong> {commission} €</Text>
                <Text><strong>Revenu du modèle :</strong> {modelRevenue} €</Text>
                 </>
                 ) : (
                  <Text><strong>Revenu total (sans modèle) :</strong> {modelRevenue} €</Text>
                 )}
                </Box>
                 )}
              <FormControl></FormControl>

      {/* Catégorie */}
      <FormControl>
        <FormLabel>Catégorie</FormLabel>
        <Select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="Nouveautés">Nouveautés</option>
          <option value="Bestseller">Bestseller</option>
          <option value="Spécial">Spécial</option>
          <option value="En création">En création</option>
          <option value="Prochainement">Prochainement</option>
        </Select>
      </FormControl>

      {/* Médias */}
      <FormControl isRequired>
        <FormLabel>Médias</FormLabel>
        <Input
          type="file"
          multiple
          onChange={handleMediaUpload}
          ref={mediaFilesRef}
        />
      </FormControl>
      {mediaFiles.map((file, index) => (
        <HStack key={index} spacing={3}>
          <span>{file.name}</span>
          <Select
            value={mediaRarities[index]}
            onChange={(e) => handleRarityChange(index, e.target.value)}
          >
            <option value="Élémentaire">Élémentaire</option>
            <option value="Singulier">Singulier</option>
            <option value="Intrigant">Intrigant</option>
            <option value="Captivant">Captivant</option>
            <option value="Mythique">Mythique</option>
          </Select>
        </HStack>
      ))}

      {/* Vidéos Originales */}
      <FormControl>
        <FormLabel>Vidéos Originales (Optionnel)</FormLabel>
        <Input
          type="file"
          multiple
          accept="video/mp4, video/webm, video/quicktime" // Ajout de video/quicktime pour .mov
          onChange={handleVideoUpload}
          ref={originalVideosRef}
        />
      </FormControl>
      {originalVideos.map((file, index) => (
        <HStack key={index} spacing={3}>
          <span>{file.name}</span>
          <Select
            value={videoRarities[index]}
            onChange={(e) => handleRarityChangeForVideo(index, e.target.value)}
          >
            <option value="Élémentaire">Élémentaire</option>
            <option value="Singulier">Singulier</option>
            <option value="Intrigant">Intrigant</option>
            <option value="Captivant">Captivant</option>
            <option value="Mythique">Mythique</option>
          </Select>
          <Text>{videoDropRates[index]}%</Text>
        </HStack>
      ))}

      {/* Champ NOUVEAU : Extraits Courts */}
      <FormControl>
        <FormLabel>Extraits Courts (Optionnel)</FormLabel>
        <Input
          type="file"
          multiple
          accept="video/mp4, video/webm, video/quicktime"
          onChange={handleShortVideoUpload}
          ref={shortVideosRef}
        />
      </FormControl>

      {/* Affichage des extraits courts avec gestion des raretés */}
      {shortVideos.map((file, index) => (
        <HStack key={index} spacing={3}>
          <span>{file.name}</span>

          {/* Sélection de la rareté pour l'extrait court */}
          <Select
            value={shortVideoRarities[index]} // Rareté de l'extrait court
            onChange={(e) =>
              handleRarityChangeForShortVideos(index, e.target.value)
            }
          >
            <option value="Élémentaire">Élémentaire</option>
            <option value="Singulier">Singulier</option>
            <option value="Intrigant">Intrigant</option>
            <option value="Captivant">Captivant</option>
            <option value="Mythique">Mythique</option>
          </Select>
        </HStack>
      ))}

      {/* Miniature */}
      <FormControl isRequired>
        <FormLabel>Miniature</FormLabel>
        <Input
          type="file"
          onChange={handleThumbnailUpload}
          accept="image/*"
          ref={thumbnailRef}
        />
      </FormControl>

      <Button type="submit" colorScheme="teal">
        Créer Caisse
      </Button>
    </VStack>
  );
};

export default CreateBoxForm;
