import React from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";

const CreateStoryForm = ({
  storyTitle,
  setStoryTitle,
  storyCreator,
  setStoryCreator,
  storyAvatar,
  setStoryAvatar,
  storyMedia,
  setStoryMedia,
  handleStorySubmit,
}) => {
  return (
    <VStack as="form" spacing={4} onSubmit={handleStorySubmit}>
      <FormControl isRequired>
        <FormLabel>Titre de la Story</FormLabel>
        <Input
          type="text"
          placeholder="Titre de la Story"
          value={storyTitle}
          onChange={(e) => setStoryTitle(e.target.value)}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Créateur de la Story</FormLabel>
        <Input
          type="text"
          placeholder="Nom du créateur"
          value={storyCreator}
          onChange={(e) => setStoryCreator(e.target.value)}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Avatar du Créateur</FormLabel>
        <Input
          type="file"
          onChange={(e) => setStoryAvatar(e.target.files[0])}
          accept="image/*"
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Média de la Story</FormLabel>
        <Input
          type="file"
          onChange={(e) => setStoryMedia(e.target.files[0])}
          accept="image/*"
        />
      </FormControl>

      <Button type="submit" colorScheme="teal">
        Créer Story
      </Button>
    </VStack>
  );
};

export default CreateStoryForm;
