import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  FormHelperText,
  useToast, // Importation du hook useToast
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { keyframes } from "@emotion/react";


const apiUrl = process.env.REACT_APP_API_URL; // Récupérer l'URL de base depuis la variable d'environnement


function ResetPasswordPageUser() {
  const { token } = useParams(); // Récupérer le token depuis l'URL
  const [newPassword, setNewPassword] = useState('');
  const [newCerticode, setNewCerticode] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Pour afficher ou masquer le mot de passe
  const navigate = useNavigate();
  const toast = useToast(); // Initialiser le hook pour les toasts

  const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 20px rgba(0, 255, 42, 0.72);
  }
  50% {
    box-shadow: 0 0 30px rgba(21, 255, 0, 0.9);
  }
  100% {
    box-shadow: 0 0 20px rgba(0, 255, 42, 0.72);
  }
`;

  // État pour validation dynamique du mot de passe
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  // Fonction de validation dynamique du mot de passe
  const validatePassword = (password) => {
    const length = password.length >= 8 && password.length <= 64;
    const lowercase = /[a-z]/.test(password);
    const uppercase = /[A-Z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidation({ length, lowercase, uppercase, number, specialChar });
    setNewPassword(password);
  };

  // Gérer la réinitialisation du mot de passe et certicode
  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/user/reset-password-user/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword, newCerticode }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Réinitialisation réussie.',
          description: 'Votre mot de passe et certicode ont été réinitialisés avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        navigate('/login'); // Rediriger vers la page de connexion
      } else {
        toast({
          title: 'Erreur de réinitialisation.',
          description: data.message || 'Erreur lors de la réinitialisation.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Erreur lors de la réinitialisation:', error);
      toast({
        title: 'Erreur serveur.',
        description: 'Une erreur s\'est produite lors de la réinitialisation.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  // Validation du certicode pour s'assurer qu'il est numérique
  const handleCerticodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setNewCerticode(value); // N'accepte que les chiffres
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100vw"
      bgGradient="linear(to-r, rgb(0, 0, 0), rgb(0, 0, 0))" // Fond noir pour la page
      overflow="hidden" // Empêche les débordements liés au zoom
      p={6}
    >
      <Box
      bg="black" // Fond noir pour le conteneur principal
      p={8}
      borderRadius="lg"
      boxShadow="0 0 20px rgba(0, 255, 42, 0.72)" // Ombre lumineuse verte
      width="400px" // Largeur fixe du formulaire
      animation={`${pulseAnimation} 2s infinite`} // Animation de pulsation continue
      transition="all 0.5s ease" // Transition fluide et légèrement plus longue
      _hover={{
      boxShadow: "0 0 50px rgba(21, 255, 0, 1), 0 0 30px rgba(0, 255, 255, 0.8)", // Ombre plus intense au survol
      transform: "scale(1.1)", // Zoom plus prononcé au survol
      filter: "brightness(1.2)", // Légère augmentation de la luminosité
      }}
       >
         <Text
        fontSize="2xl"
        fontWeight="bold"
        textAlign="center"
        color="white"
        mb={4}
        >
        Réinitialisation du mot de passe et certicode
        </Text>
          
  
          {/* Formulaire avec classe spécifique */}
          <Box
          as="form"
          className="reset-password-form" // Ajout de la classe unique
          onSubmit={handleResetPassword}
          p={8}
          borderRadius="lg"
          bg="black" // Changer la couleur du fond en noir
            >
            {/* Nouveau mot de passe */}
            <FormControl id="new-password" isRequired bg="black"> {/* Ajout du fond noir */}
              <FormLabel color="white">Nouveau mot de passe</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => validatePassword(e.target.value)}
                  borderRadius="full"
                  h="40px"
                  px={4}
                  fontSize="lg"
                  borderColor="green.400"
                  focusBorderColor="green.500"
                  bg="#1e1e1e" // Fond noir pour le champ
                  color="white" // Texte blanc
                />
                <InputRightElement h="full">
                  <Button
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <ViewOffIcon
                        style={{
                          fontSize: "1.5rem",
                          background: "linear-gradient(to-r, #7FFF00, #32CD32)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent", // Rendre le fond du texte transparent pour voir le dégradé
                        }}
                      />
                    ) : (
                      <ViewIcon
                        style={{
                          fontSize: "1.5rem",
                          background: "linear-gradient(to-r, #7FFF00, #32CD32)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent", // Rendre le fond du texte transparent pour voir le dégradé
                        }}
                      />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormHelperText color="white" bg="black" p={3} borderRadius="lg">
                Le mot de passe doit répondre aux critères suivants :
              </FormHelperText>
              <Box mt={2} bg="black" p={3} borderRadius="lg">
                <Text color={passwordValidation.length ? "green.500" : "red.500"}>
                  • 8 à 64 caractères
                </Text>
                <Text color={passwordValidation.lowercase ? "green.500" : "red.500"}>
                  • Inclure une lettre minuscule
                </Text>
                <Text color={passwordValidation.uppercase ? "green.500" : "red.500"}>
                  • Inclure une lettre majuscule
                </Text>
                <Text color={passwordValidation.number ? "green.500" : "red.500"}>
                  • Inclure un chiffre
                </Text>
                <Text color={passwordValidation.specialChar ? "green.500" : "red.500"}>
                  • Inclure un caractère spécial
                </Text>
              </Box>
            </FormControl>
  
            {/* Nouveau certicode */}
            <FormControl id="new-certicode" mt={4} isRequired bg="black">
              <FormLabel color="white">Nouveau certicode</FormLabel>
              <Input
                type="text"
                maxLength="6"
                value={newCerticode}
                onChange={handleCerticodeChange}
                borderRadius="full"
                h="40px"
                px={4}
                fontSize="lg"
                borderColor="green.400"
                focusBorderColor="green.500"
                bg="#1e1e1e"
                color="white"
              />
              <FormHelperText color="white" bg="black">
                6 chiffres requis
              </FormHelperText>
            </FormControl>
  
            {/* Bouton de réinitialisation */}
            <Button
              type="submit"
              mt={6}
              width="100%"
              bgGradient="linear(to-r, #7FFF00, #32CD32)"
              color="black"
              _hover={{
                bgGradient: "linear(to-r, #32CD32, #7FFF00)",
                transform: "scale(1.05)",
              }}
              h="60px"
              borderRadius="full"
              fontSize="lg"
            >
              Réinitialiser
            </Button>
      </Box>
    </Box>
  </Box>

  );
}

export default ResetPasswordPageUser;
