import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './styles/BoxDetailPage.css';
import { ChakraProvider } from '@chakra-ui/react'; // Importation du ChakraProvider



// === Gestionnaires globaux pour capturer les erreurs ===
window.addEventListener("error", (event) => {
  console.error("Erreur capturée :", event.message);
  console.error("Source :", event.filename);
  console.error("Ligne :", event.lineno);
  console.error("Colonne :", event.colno);
  console.error("Erreur complète :", event.error);
});



// Gestionnaire global pour capturer les promesses non gérées
window.addEventListener('unhandledrejection', (event) => {
  console.error('Promesse non gérée détectée :', event.reason);
});


// Importer et initialiser Sentry
/*
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
*/

// Supprimez ou commentez cette partie si vous n’utilisez pas Sentry
/*
Sentry.init({
  dsn: 'YOUR_SENTRY_DSN',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider> {/* Enveloppe toute l'application avec ChakraProvider */}
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Désactivez temporairement reportWebVitals
// reportWebVitals();
