//Ce composant gère le menu utilisateur avec ses différentes options.

import { Box, Text, Button } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';

const UserMenu = ({
  username,
  userKeysBalance,
  userRole,
  modelId,
  showMenu,
  toggleMenu,
  navigate,
  handleLogout,
}) => {
  return (
    <Box className="user-menu">
      <Text onClick={toggleMenu} cursor="pointer" color="purple.300" fontWeight="bold">
        Bienvenue, {username}!
      </Text>
      {showMenu && (
        <Box className="dropdown-menu" mt={2}>
          {userKeysBalance !== null && (
            <Text fontSize="lg" color="yellow.300" fontWeight="bold" fontFamily="Roboto">
              🔑 Clés disponibles : {userKeysBalance}
            </Text>
          )}
          {userRole === 'user' && (
            <>
              <Button onClick={() => navigate('/inventory')} variant="outline" size="sm" color="#FF007F">
                Inventaire
              </Button>
              <Button
                onClick={() => navigate('/buy-keys')}
                variant="outline"
                size="sm"
                mt={2}
                color="#FF007F"
              >
                Achat de clés
              </Button>
            </>
          )}
          {userRole === 'model' && modelId && (
            <Button
              onClick={() => navigate(`/model/profit/${modelId}`)}
              variant="outline"
              size="sm"
              mt={2}
              color="#FF007F"
            >
              Statistiques
            </Button>
          )}
          <Button
            onClick={() => {
              const role = localStorage.getItem('role');
              navigate(role === 'model' ? '/model/settings' : '/settings');
            }}
            variant="outline"
            size="sm"
            mt={2}
            leftIcon={<SettingsIcon />}
            color="#FF007F"
          >
            Paramètres
          </Button>
          <Button onClick={handleLogout} variant="outline" size="sm" mt={2} color="#FF007F">
            Déconnexion
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UserMenu;
