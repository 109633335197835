import React, { useState } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaTimesCircle } from 'react-icons/fa';

const ResultOverlay = ({ result, apiUrl, onClose }) => {

  const [isDragged, setIsDragged] = useState(false); // État pour gérer le glissement

  // Gérer le glissement vers le haut
  const handleDragEnd = (event, info) => {
    if (info.offset.y < -150) {
      setIsDragged(true); // Applique la classe pour glisser
      setTimeout(() => {
        onClose(); // Ferme l'overlay
        window.location.reload(); // Retirez ou commentez cette ligne pour désactiver le rafraîchissement
      }, 500); // Laisse le temps à l'animation de se terminer
    }
  };

  return (
  <Box
    className={`result-overlay ${isDragged ? 'drag-up' : ''}`}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000, // Assure que l'image est au premier plan
    }}
    onClick={(e) => e.stopPropagation()} // Empêche la fermeture accidentelle
  >
    <motion.div
      drag="y"
      dragConstraints={{ top: -200, bottom: 0 }}
      dragElastic={0.2}
      onDragEnd={handleDragEnd} // Ajoute la gestion du glissement
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Text fontSize="2xl" color="white" mb={4}>
        Vous avez obtenu :
      </Text>

    
      {/* Affichage du média gagné : vidéo ou image */}
      {result.endsWith('.mp4') || result.endsWith('.webm') || result.endsWith('.mov') ? (
        <video
          src={`${apiUrl}/uploads/${result}`}
          alt="result"
          className="result-media"
          style={{
            maxWidth: '90%',
            maxHeight: '90vh',
          }}
          autoPlay
          controls
        />
      ) : (
        <Image
          src={`${apiUrl}/uploads/${result}`}
          alt="result"
          className="result-image"
          style={{
            maxWidth: '90%',
            height: '90',
            maxHeight: '90vh', // Limite la hauteur pour qu’elle s’adapte à l’écran
          }}
            mx="auto"
            draggable="true"
            onContextMenu={(e) => e.stopPropagation()}
        />
      )}

      {/* Icône de téléchargement */}
      <a href={`${apiUrl}/uploads/${result}`} download>
        <motion.div
          whileHover={{ scale: 1.2 }}
          style={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            backgroundColor: '#000',
            padding: '10px',
            borderRadius: '50%',
            zIndex: 10,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M5 20h14v-2H5v2zm7-18l-7 7h4v6h6v-6h4l-7-7z" />
          </svg>
        </motion.div>
      </a>

      {/* Icône de fermeture */}
      <FaTimesCircle
        onClick={onClose}
        size={40}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: 'white',
          cursor: 'pointer',
        }}
      />
    </motion.div>
  </Box>
);
};

export default ResultOverlay;
