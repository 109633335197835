//Ce composant affichera les utilisateurs et les boîtes ouvertes.

import { Flex, Text } from '@chakra-ui/react';

const HeaderLeft = ({ totalUsers, totalCasesOpened }) => {
  return (
    <Flex className="header-left" alignItems="center">
      <Text fontSize="lg" color="white" fontWeight="bold" fontFamily="Roboto">
        👤 {totalUsers} UTILISATEURS
      </Text>
      <Text fontSize="lg" color="white" fontWeight="bold" fontFamily="Roboto" ml={4}>
        📦 {totalCasesOpened} BOXES
      </Text>
    </Flex>
  );
};

export default HeaderLeft;
