import React from "react";
import { Box, Input, FormControl, FormLabel } from "@chakra-ui/react";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box mb={5}>
      <FormControl>
        <FormLabel>Rechercher un modèle</FormLabel>
        <Input
          type="text"
          placeholder="Entrez un nom d'utilisateur"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </FormControl>
    </Box>
  );
};

export default SearchBar;
