import React from 'react';
import { Box, VStack, Heading, Image, Text, Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'; // Importez Link de react-router-dom
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function IndependentBlock({ lastOpenings, userOpenings }) {
  const isDirectOpenings = lastOpenings.length > 0;

  return (
    <>
      {/* Section "Ouvertures en direct" */}
      <Box className="direct-openings-section">
        {isDirectOpenings && (
          <VStack align="flex-start" spacing={4}>
            <Box>
              <Heading className="openings-section-heading" size="md" color="#FF007F" mb={3}>
                🔥 Ouvertures en direct
              </Heading>
              <ul className="openings-list">
                {lastOpenings.map((opening, index) => (
                  <li className="openings-list-item" key={index}>
                    <Flex alignItems="center">
                      <Image
                        src={`${apiUrl}/uploads/${opening.boxId?.thumbnail}`}
                        alt={opening.boxId?.title || 'Thumbnail'}
                        className="openings-list-item-image"
                      />
                      <Text>
                        {opening.userId?.username || 'Utilisateur supprimé'} a ouvert{' '}
                        <strong>
                          <Link
                            as={RouterLink}
                            to={`/box/${opening.boxId?._id}`}
                            color="blue.400"
                            _hover={{ textDecoration: 'underline' }}
                          >
                            {opening.boxId?.title || 'une caisse'}
                          </Link>
                        </strong>{' '}
                        le {new Date(opening.purchaseDate).toLocaleString()}
                      </Text>
                    </Flex>
                  </li>
                ))}
              </ul>
            </Box>
          </VStack>
        )}
      </Box>

      {/* Section "Vos dernières ouvertures" */}
      <Box className="user-openings-section">
        {!isDirectOpenings && (
          <VStack align="flex-start" spacing={4}>
            <Box>
              <Heading className="openings-section-heading" size="md" color="#FF007F" mb={3}>
                🕒 Vos dernières ouvertures
              </Heading>
              <ul className="openings-list">
                {userOpenings.map((opening, index) => (
                  <li className="openings-list-item" key={index}>
                    <Flex alignItems="center">
                      <Image
                        src={`${apiUrl}/uploads/${opening.boxId?.thumbnail}`}
                        alt={opening.boxId?.title || 'Thumbnail'}
                        className="openings-list-item-image"
                      />
                      <Text>
                        Vous avez ouvert{' '}
                        <strong>
                          <Link
                            as={RouterLink}
                            to={`/box/${opening.boxId?._id}`}
                            color="blue.400"
                            _hover={{ textDecoration: 'underline' }}
                          >
                            {opening.boxId?.title || 'une caisse'}
                          </Link>
                        </strong>{' '}
                        le {new Date(opening.purchaseDate).toLocaleString()}
                      </Text>
                    </Flex>
                  </li>
                ))}
              </ul>
            </Box>
          </VStack>
        )}
      </Box>
    </>
  );
}

export default IndependentBlock;