import React, { useEffect } from "react";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    document.title = "Politique de confidentialité - Onlybox";
  }, []);

  return (
    <div className="privacy-policy-body">
      <h1>Politique de confidentialité</h1>
      <p>
        Nous respectons votre vie privée. Voici notre politique de confidentialité,
        expliquant comment nous collectons, utilisons et protégeons vos données personnelles.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
