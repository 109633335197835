//Ce composant regroupe les sous-composants HeaderLeft, UserMenu, et MenuHamburger.

import { Flex } from '@chakra-ui/react';
import HeaderLeft from './HeaderLeft';
import UserMenu from './UserMenu';
import MenuHamburger from './MenuHamburger';

const Header = ({
  totalUsers,
  totalCasesOpened,
  username,
  userKeysBalance,
  userRole,
  modelId,
  showMenu,
  toggleMenu,
  navigate,
  handleLogout,
}) => {
  return (
    <header className="header">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <HeaderLeft totalUsers={totalUsers} totalCasesOpened={totalCasesOpened} />
        <Flex className="header-right" alignItems="center">
          {username ? (
            <UserMenu
              username={username}
              userKeysBalance={userKeysBalance}
              userRole={userRole}
              modelId={modelId}
              showMenu={showMenu}
              toggleMenu={toggleMenu}
              navigate={navigate}
              handleLogout={handleLogout}
            />
          ) : (
            <MenuHamburger navigate={navigate} />
          )}
        </Flex>
      </Flex>
    </header>
  );
};

export default Header;
