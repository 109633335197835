import axios from 'axios';

// Configuration de l'instance Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // URL de base de votre backend
});

// Intercepteur pour inclure le token d'autorisation dans les requêtes
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Récupère le token depuis le localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Ajoute le token dans les en-têtes
    }
    return config; // Retourne la configuration de la requête modifiée
  },
  (error) => {
    return Promise.reject(error); // Gère les erreurs de configuration de la requête
  }
);

// Intercepteur pour gérer les erreurs 401 (Unauthorized)
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Vérifie si la réponse est une erreur 401
    if (error.response && error.response.status === 401) {
      const errorMessage = error.response.data?.message;

      // Si le token est explicitement révoqué (par exemple, suppression de compte)
      if (errorMessage === 'Token révoqué' || errorMessage === 'Compte supprimé') {
        // Supprimer les données locales
        localStorage.removeItem('token');
        localStorage.removeItem('userId');

        // Rediriger vers la page de connexion
        window.location.href = '/login';
      }

      // Si l'erreur n'est pas liée à une révocation, retourner l'erreur sans déconnecter l'utilisateur
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
