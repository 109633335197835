import axios from '../utils/axios';


/**
 * Récupérer les détails de la box à partir de l'API
 * @param {string} apiUrl - L'URL de l'API
 * @param {string} boxId - L'ID de la box
 * @returns {Promise<Object>} - Les détails de la box
 */

// Récupérer les détails de la box
export const fetchBoxDetails = async (apiUrl, boxId) => {
  try {
    const response = await axios.get(`${apiUrl}/api/admin/boxes/${boxId}/view`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des détails de la caisse :', error);
    throw error;
  }
};

/**
 * Récupérer le solde des clés de l'utilisateur
 * @param {string} apiUrl - L'URL de l'API
 * @param {string} userId - L'ID de l'utilisateur
 * @returns {Promise<number>} - Le solde des clés
 */
export const fetchKeysBalance = async (userId) => {
    if (!userId || userId === 'undefined') {
      throw new Error("L'ID utilisateur est introuvable ou invalide");
    }
    try {
      const response = await axios.get(`/api/user/keys-balance/${userId}`);
      return response.data.keys_balance;
    } catch (error) {
      console.error('Erreur lors de la récupération du solde des clés :', error);
      throw error;
    }
  };

/**
 * Ouvrir une box
 * @param {string} apiUrl - L'URL de l'API
 * @param {string} userId - L'ID de l'utilisateur
 * @param {string} boxId - L'ID de la box
 * @param {number} boxPrice - Le prix de la box
 * @returns {Promise<Object>} - Les résultats de la box ouverte
 */
export const openBox = async (apiUrl, userId, boxId, boxPrice) => {
  try {
    const response = await axios.post(`${apiUrl}/api/box/open-box`, { userId, boxId, boxPrice });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'ouverture de la caisse:', error);
    throw error;
  }
};

/**
 * Enregistrer un achat
 * @param {string} apiUrl - L'URL de l'API
 * @param {Object} purchaseData - Les données de l'achat
 * @returns {Promise<Object>} - La réponse du serveur après enregistrement
 */
export const registerPurchase = async (apiUrl, purchaseData) => {
    if (!purchaseData.countryCode || purchaseData.countryCode.length !== 2) {
      console.error("Erreur : Code pays invalide ou non défini :", purchaseData.countryCode);
      throw new Error("Code pays invalide ou non défini");
    }
  
    try {
      console.log("Données envoyées pour l'achat :", purchaseData);
      const response = await axios.post(`${apiUrl}/api/purchase/create-box-purchase`, purchaseData);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de l\'achat :', error.response?.data || error.message);
      throw error;
    }
  };
