import React, { useState, useEffect, useCallback } from 'react'; // Ajout de useCallback
import { useNavigate, Link } from 'react-router-dom';
import {
  useToast,
  Button,
  Input,
  Box,
  Heading,
  Text,
  Spinner,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [certicode, setCerticode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [autoSubmitEnabled, setAutoSubmitEnabled] = useState(true);
  const [attempts, setAttempts] = useState(0); // Nombre de tentatives de connexion

  const navigate = useNavigate();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fonction pour vérifier la validité des champs
  const areFieldsValid = useCallback(() => {
    return (
      email.trim() !== '' &&
      password.trim() !== '' &&
      certicode.trim() !== '' &&
      certicode.length === 6
    );
  }, [email, password, certicode]); // Ajout des dépendances nécessaires

  // Fonction pour gérer la soumission du formulaire
  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();

      // Vérifier la validité des champs avant d'envoyer une requête
    if (!areFieldsValid()) {
      toast({
        title: 'Champs invalides',
        description: 'Veuillez remplir correctement tous les champs.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    if (isLoading || attempts >= 3) return; // Limite des tentatives

    setIsLoading(true);

      try {
        const response = await fetch(`${apiUrl}/api/user/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password, certicode }),
        });

        const data = await response.json();

        if (response.ok) {
          setTimeout(() => {
            setIsLoading(false);
            setHasLoginFailed(false); // Réinitialiser l'état d'échec
            setAutoSubmitEnabled(true); // Réactiver la soumission automatique

            if (data.token) {
              localStorage.setItem('token', data.token);
            }

            localStorage.setItem('userId', data.userId);
            localStorage.setItem('username', data.username);
            localStorage.setItem('role', 'user');

            if (data.country) {
              localStorage.setItem('userCountry', data.country);
            }

            if (data.address) {
              localStorage.setItem('userAddress', JSON.stringify(data.address));
            }

            toast({
              title: '👋 Bienvenue !',
              description: `Content de vous revoir, ${data.username} !`,
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });

            navigate('/');
          }, 1000);
        } else {
          setIsLoading(false);
          setHasLoginFailed(true); // Marquer l'échec de connexion
          setTimeout(() => {
          setAutoSubmitEnabled(false); // Désactiver la soumission automatique
          }, 100);
          setAttempts((prev) => prev + 1); // Incrémenter les tentatives
          toast({
            title: 'Erreur de connexion',
            description:
              data.message || 'Email, mot de passe ou certicode incorrect.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        }
      } catch (error) {
        setIsLoading(false);
        setHasLoginFailed(true); // Marquer l'échec de connexion
        setTimeout(() => {
        setAutoSubmitEnabled(false); // Désactiver la soumission automatique
        }, 100);
        console.error('Erreur lors de la connexion:', error);
        toast({
          title: 'Erreur serveur',
          description:
            "Une erreur s'est produite lors de la connexion. Veuillez réessayer.",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    },
    [email, password, certicode, isLoading, apiUrl, toast, navigate, areFieldsValid, attempts] // Ajout des dépendances nécessaires
  );

  
  useEffect(() => {
    if (hasLoginFailed) {
      setHasLoginFailed(false);
      setAutoSubmitEnabled(true); // Réactiver la soumission automatique
    }
  }, [email, password, certicode,hasLoginFailed, areFieldsValid]);

  useEffect(() => {
    if (areFieldsValid() && autoSubmitEnabled && !hasLoginFailed) {
      handleLogin(new Event('submit')); // Appel de la fonction handleLogin
    }
  }, [email, password, certicode, areFieldsValid, handleLogin, autoSubmitEnabled, hasLoginFailed]);
  

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100vw" // Utiliser toute la largeur de l'écran
      bgGradient="linear(to-r,rgb(0, 0, 0),rgb(0, 0, 0))"
    >
      <Box
        as="form"
        onSubmit={handleLogin}
        p="6"
        rounded="lg"
        boxShadow="0 0 20px rgba(0, 255, 42, 0.72)"
        bg="black" // Changer la couleur du fond en noir
        width="400px"
        transition="all 0.3s ease"
        _hover={{
          boxShadow: '0 0 30px rgba(21, 255, 0, 0.8)',
          transform: 'scale(1.05)',
        }}
      >
        <Heading
          textAlign="center"
          mb={6}
          sx={{
            background: 'linear-gradient(to right, red, orange, yellow, green, indigo, violet)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            transition: 'transform 0.2s',
            _hover: {
              transform: 'scale(1.1)',
            },
          }}
        >
          Onlybox
        </Heading>

        <Heading as="h2" mb={4} textAlign="center" color="white" fontWeight="bold">
          Accédez à votre compte
        </Heading>

        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={3}
          borderColor="green.400" // Bordure verte
          focusBorderColor="green.500" // Bordure verte lumineuse au focus
          _hover={{ borderColor: 'green.500' }} // Bordure verte au survol
          borderRadius="full" // Coins complètement arrondis // Utilise `sx` pour ajouter `!important`
          h="40px" // Hauteur pour un effet de pilule
          px={4} // Padding horizontal pour aérer
          fontSize="lg" // Taille de texte légèrement plus grande
          boxShadow="0 0 10px rgba(0, 255, 0, 0.3)" // Ombre subtile pour l'effet
          bg="#1e1e1e" // Fond noir ou gris foncé
          color="#fff" // Texte blanc pour le contraste
          disabled={isLoading}
        />

        <InputGroup mb={3}>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            borderColor="green.400"
            focusBorderColor="green.500"
            _hover={{ borderColor: 'green.500' }}
            borderradius="full"
            h="40px" // Hauteur pour un effet de pilule
            px={4} // Padding horizontal pour aérer
            fontSize="lg"
            boxShadow="0 0 10px rgba(0, 255, 0, 0.3)" // Ombre subtile pour l'effet
            bg="#1e1e1e" // Fond noir ou gris foncé
            color="#fff" // Texte blanc pour le contraste
            disabled={isLoading}
          />
          <InputRightElement width="3rem">
            <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            </Button>
          </InputRightElement>
        </InputGroup>

        <Input
          type="text"
          placeholder="Certicode (Code personnel)"
          value={certicode}
          onChange={(e) => setCerticode(e.target.value)}
          mb={3}
          maxLength={6}
          pattern="\d{6}"
          inputMode="numeric"
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          borderColor="green.400"
          focusBorderColor="green.500"
          _hover={{ borderColor: 'green.500' }}
          borderradius="full"
          h="40px" // Hauteur pour un effet de pilule
          px={4} // Padding horizontal pour aérer
          fontSize="lg"
          boxShadow="0 0 10px rgba(0, 255, 0, 0.3)" // Ombre subtile pour l'effet
          bg="#1e1e1e" // Fond noir ou gris foncé
          color="#fff" // Texte blanc pour le contraste
          disabled={isLoading}
        />

       <Button
       type="submit"
       width="100%"
       bgGradient="linear(to-r, #7FFF00, #32CD32)" // Dégradé vert
       color="black" // Couleur du texte
       _hover={{
       bgGradient: 'linear(to-r, #32CD32, #7FFF00)', // Dégradé inversé au survol
       transform: 'scale(1.05)', // Légère augmentation au survol
       }}
       size="lg"
       disabled={isLoading} // Désactive le bouton si "isLoading" est vrai
       >
       {isLoading ? <Spinner size="sm" /> : 'Se connecter'}
       </Button>

       <Text
       mt={4}
       textAlign="center"
       color="green.400" // Vert similaire
      fontWeight="bold" // Texte en gras
      fontSize="sm" // Taille légèrement plus petite
      textTransform="uppercase" // Pour tout mettre en majuscules
      >
      <Link
    to="/forgot-password-user"
    style={{
      textDecoration: 'none', // Retirer le soulignement
      color: 'inherit', // Utilise la même couleur que le parent (vert)
    }}
  >
    Mot de passe oublié ?
  </Link>
</Text>
      </Box>
    </Box>
  );
}

export default LoginPage;