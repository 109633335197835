import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import Slider from 'react-slick';

const Carousel = ({ carouselImages, carouselSettings, apiUrl }) => {
  return (
    <Box className="carousel-container" marginBottom={5}>
      <Slider {...carouselSettings}>
        {carouselImages.length > 0 &&
          carouselImages.map((carouselItem) =>
            carouselItem.images.map((image, index) => (
              <Box key={`${carouselItem._id}-${index}`} textAlign="center">
                <Image
                  src={`${apiUrl}/uploads/${image}`}
                  alt={`Carousel Image ${index + 1}`}
                  objectFit="cover"
                  width="100%"
                  height="400px"
                  borderRadius="10px"
                  margin="0 auto"
                />
              </Box>
            ))
          )}
      </Slider>
    </Box>
  );
};

export default Carousel;
