import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Select,
  List,
  ListItem,
  HStack,
  Image,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";

const SuggestionsManager = ({ apiUrl, boxes, userId }) => {
  const [suggestions, setSuggestions] = useState([]); // Liste des suggestions
  const [selectedBoxId, setSelectedBoxId] = useState(""); // État pour gérer la boîte sélectionnée

  const toast = useToast();

  // Fonction pour récupérer les suggestions
  const fetchSuggestions = useCallback(async () => {
    try {
        const response = await fetch(`${apiUrl}/api/suggestions/admin`);
      const data = await response.json();

      // Ajout de la logique `alreadyOpened`
      const updatedSuggestions = data.map((suggestion) => ({
        ...suggestion,
        alreadyOpened: suggestion.openedByUsers?.includes(userId), // Vérifie si l'utilisateur a ouvert cette boîte
      }));

      setSuggestions(updatedSuggestions); // Mettez à jour les suggestions avec `alreadyOpened`
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions :", error);
      toast({
        title: "Erreur",
        description: "Impossible de récupérer les suggestions.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [apiUrl, toast, userId]);

  const handleAddSuggestion = async (boxId) => {
    try {
      if (!boxId) {
        toast({
          title: "Erreur",
          description: "Veuillez sélectionner une boîte avant d'ajouter.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
        const response = await fetch(`${apiUrl}/api/suggestions`, { // Chemin mis à jour
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ boxId }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setSuggestions((prevSuggestions) => [...prevSuggestions, data.suggestion]);
        toast({
          title: "Succès",
          description: "Boîte ajoutée aux suggestions.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(data.message || "Erreur lors de l'ajout aux suggestions.");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout aux suggestions :", error);
      toast({
        title: "Erreur",
        description: "Impossible d'ajouter la boîte aux suggestions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour supprimer une boîte des suggestions
  const handleRemoveSuggestion = async (boxId) => {
    try {
        await fetch(`${apiUrl}/api/suggestions/${boxId}`, { // Chemin mis à jour
        method: "DELETE",
      });

      setSuggestions((prevSuggestions) =>
        prevSuggestions.filter((box) => box._id !== boxId)
      );
      toast({
        title: "Succès",
        description: "La boîte a été retirée des suggestions.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression des suggestions :", error);
      toast({
        title: "Erreur",
        description: "Impossible de supprimer la boîte des suggestions.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Charger les suggestions au montage du composant
  useEffect(() => {
    fetchSuggestions();
  }, [fetchSuggestions]);

  return (
    <Box mt={10}>
      <Heading size="md" mb={5}>
        Gestion des Suggestions
      </Heading>

      <FormControl mb={5}>
  <FormLabel>Ajouter une boîte aux suggestions</FormLabel>
  <Select
    placeholder="Sélectionnez une boîte"
    value={selectedBoxId}
    onChange={(e) => setSelectedBoxId(e.target.value)} // Mets à jour l'état pour stocker l'ID de la boîte sélectionnée
  >
    {boxes.map((box) => (
      <option key={box._id} value={box._id}>
        {box.title} - {parseFloat(box.price).toFixed(2)}€
      </option>
    ))}
  </Select>
  <Button
    mt={3}
    colorScheme="green"
    onClick={() => handleAddSuggestion(selectedBoxId)} // Appelle la fonction pour ajouter la boîte aux suggestions
    isDisabled={!selectedBoxId} // Désactive le bouton tant qu'aucune boîte n'est sélectionnée
  >
    Ajouter aux Suggestions
  </Button>
</FormControl>

      {/* Liste des suggestions actuelles */}
      <Heading size="sm" mb={3}>
        Suggestions actuelles
      </Heading>
      <List spacing={3}>
  {suggestions.map((suggestion) => (
    <ListItem
      key={suggestion._id}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <HStack>
        {/* Affiche l'image principale de la boîte */}
        <Image
          src={suggestion.thumbnail ? `${apiUrl}/uploads/${suggestion.thumbnail}` : "/default-thumbnail.png"}
          alt={suggestion.title}
          boxSize="50px"
          borderRadius="md"
        />
        {/* Affiche le titre de la boîte */}
        <Text>{suggestion.title}</Text>
      </HStack>
      <Button
        size="sm"
        colorScheme="red"
        onClick={() => handleRemoveSuggestion(suggestion._id)}
      >
        Retirer
      </Button>
    </ListItem>
  ))}
</List>
    </Box>
  );
};

export default SuggestionsManager;
