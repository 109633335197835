import React, { useState, useEffect } from "react";
import "../styles/CookieConsent.css";
import { useToast } from "@chakra-ui/react"; // Si vous utilisez Chakra UI




const CookieConsent = () => {
  const toast = useToast();



   // Ajoutez des états pour gérer le rôle et l'ID utilisateur
   const [userId, setUserId] = useState("guest_user"); // Par défaut pour les invités
   const [role, setRole] = useState("guest"); // Par défaut pour les invités
 
   useEffect(() => {
     // Récupérer l'ID utilisateur et le rôle depuis localStorage ou une source externe
     const storedUserId = localStorage.getItem("userId");
     const storedRole = localStorage.getItem("role");
 
     if (storedUserId && storedRole) {
       setUserId(storedUserId);
       setRole(storedRole);
     }
   }, []); // Exécuté une seule fois au montage






  // Initialisez directement `isVisible` en fonction de `localStorage`
  const [isVisible, setIsVisible] = useState(() => {
    const hasSeenConsent = localStorage.getItem("hasSeenCookiesConsent");
    const currentPath = window.location.pathname; // Récupère le chemin actuel
    const isPolicyPage =
      currentPath === "/cookies-policy" || currentPath === "/privacy-policy"; // Vérifie les nouvelles routes

    return hasSeenConsent !== "true" && !isPolicyPage; // Affiche la modal si non vu et pas sur ces pages
  });
  
  
  
  

  const [showCustomization, setShowCustomization] = useState(false); // Affiche la personnalisation
  const [activeCategory, setActiveCategory] = useState(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false); // Gestion de l'overlay
  const [overlayType, setOverlayType] = useState(null); // Définit le type d'overlay
  const [preferences, setPreferences] = useState({
    thirdPartyCookies: false, // Nouveau toggle
    adobeMarketo: false,
    googleAds: false,
    linkedinMarketing: false,
    adsX: false,
    analytics: false,// Catégorie principale
    googleAnalytics: false, // Nouvelle sous-catégorie pour Analyse
    marketing: false,
    content: false, // Catégorie principale
    awsAmazonContent: false, // Sous-catégorie
    giphyContent: false, // Sous-catégorie
    googleMediaContent: false, // Sous-catégorie
    contentX: false, // Sous-catégorie
    youtubeContent: false, // Sous-catégorie
    productivity: false, // Ajout de la clé Productivité
    chiliPiper: false, // Nouvelle sous-catégorie pour Chili Piper
    googleProductivity: false, // Nouvelle sous-catégorie pour Google Productivité
    advertising: false,

  });

 
  // Gère l'acceptation de tous les cookies
  const handleAcceptAll = async () => {
    const consent = { all: true, analytics: true, marketing: true, content: true, productivity: true, advertising: true };
    try {
      // Logique d'acceptation complète
      console.log("Début de l'acceptation de tous les cookies...");
  
      // Sauvegarde dans le cookie
      document.cookie = `userConsent=all; path=/; max-age=31536000`;
      console.log("Consentement sauvegardé dans le cookie.");
  
      // Mise à jour dans le localStorage
      localStorage.setItem("hasSeenCookiesConsent", "true");
      console.log("Consentement enregistré dans le localStorage.");
  
      // Sauvegarde dans le backend
      await savePreferencesToServer(consent);
      console.log("Consentement sauvegardé dans le backend :", consent);
  
      // Mise à jour du state React
      setPreferences(consent);
      console.log("State des préférences mis à jour :", consent);
  
      // Fermeture de la modal
    setIsVisible(false);
    console.log("Modal de cookies fermée.");

    // Affichage d'un toast de succès
    toast({
      title: "Tous les cookies acceptés.",
      description: "Votre consentement a été enregistré avec succès.",
      status: "success", // Statut du toast : success
      duration: 3000, // Durée d'affichage en millisecondes
      isClosable: true, // Le toast peut être fermé manuellement
      position: "top-right", // Position du toast
    });
  } catch (error) {
    console.error("Erreur lors de l'acceptation de tous les cookies :", error);

    // Affichage d'un toast d'erreur
    toast({
      title: "Erreur",
      description: "Une erreur est survenue lors de l'acceptation des cookies.",
      status: "error", // Statut du toast : error
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  }
};


  // Fonction pour gérer le clic sur un lien hypertexte
  const handleLinkClick = (event) => {
    event.preventDefault(); // Empêche le rechargement de la page
    setIsVisible(false); // Ferme la modal
    setTimeout(() => {
    window.location.href = "/cookies-policy"; // Navigue vers la page
  }, 300); // Délai pour une transition fluide
  };

  // Fonction pour gérer le clic sur un lien hypertexte
  const handlePrivacyLinkClick = (event) => {
    event.preventDefault(); // Empêche le rechargement de la page
    setIsVisible(false); // Ferme la modal
    setTimeout(() => {
    window.location.href = "/privacy-policy"; // Navigue vers la page
  }, 300); // Délai pour une transition fluide
  };


   // Fonction pour sauvegarder les préférences dans le backend
   const savePreferencesToServer = async (consent) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL; // URL de base
  
      // Récupérer les informations depuis le localStorage
      const userId = localStorage.getItem("userId") || "guest_user";
      const role = localStorage.getItem("role") || "guest";
      const modelId = localStorage.getItem("modelId");
  
      // Ajouter la structure pour inclure les sous-catégories
    const payload = {
      role,
      consent: {
        ...consent,
        advertising: {
          enabled: consent.advertising,
          subCategories: {
            adobeMarketo: consent.adobeMarketo || false,
            googleAds: consent.googleAds || false,
            linkedinMarketing: consent.linkedinMarketing || false,
            adsX: consent.adsX || false,
          },
        },

        analytics: {
          enabled: consent.analytics,
          subCategories: {
            googleAnalytics: consent.googleAnalytics || false, // Nouvelle sous-catégorie
          },
        },
        content: {
          enabled: consent.content,
          subCategories: {
            awsAmazonContent: consent.awsAmazonContent || false,
            giphyContent: consent.giphyContent || false,
            googleMediaContent: consent.googleMediaContent || false,
            contentX: consent.contentX || false,
            youtubeContent: consent.youtubeContent || false,
          },
        },
        productivity: {
          enabled: consent.productivity,
          subCategories: {
            chiliPiper: consent.chiliPiper || false, // Nouvelle sous-catégorie
            googleProductivity: consent.googleProductivity || false, // Nouvelle sous-catégorie
          },
        },
      },
    };

    // Remplir le payload avec les identifiants appropriés
    if (role === "user" && userId) {
      payload.userId = userId; // Remplir userId si c'est un utilisateur
    } else if (role === "model" && modelId) {
      payload.modelId = modelId; // Remplir modelId si c'est un modèle
    } else {
      payload.userId = "guest_user"; // Par défaut pour les invités
    }

    console.log("Payload envoyé au backend :", payload);

    console.log("Payload envoyé au backend :", payload);
  
      const response = await fetch(`${apiUrl}/api/consent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        console.error("Erreur lors de la sauvegarde des préférences");
      } else {
        console.log("Préférences sauvegardées avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de la connexion au serveur :", error);
    }
  };

  

  // Gère le refus des cookies optionnels
  const handleRejectOptional = () => {
    const consent = { all: false, analytics: false, marketing: false, content: false, productivity: false, advertising: false  };
    document.cookie = "userConsent=essential; path=/; max-age=31536000";
    localStorage.setItem("hasSeenCookiesConsent", "true"); // Sauvegarde l'état
    savePreferencesToServer(consent);
    setIsVisible(false);
  };

  // Affiche le panneau de personnalisation
  const handleCustomize = () => {
    setShowCustomization(true);
  };

  // Sauvegarde les préférences choisies
const handleSavePreferences = async () => {
  try {
    console.log("Début de la sauvegarde des préférences...");
    console.log("Préférences actuelles :", preferences);


    // Construire les préférences avec les sous-catégories
    const consent = {
      ...preferences,
      advertising: {
        enabled: preferences.advertising || false,
        subCategories: {
          adobeMarketo: preferences.adobeMarketo || false,
          googleAds: preferences.googleAds || false,
          linkedinMarketing: preferences.linkedinMarketing || false,
          adsX: preferences.adsX || false,
        },
      },
      analytics: {
        enabled: preferences.analytics || false,
        subCategories: {
          googleAnalytics: preferences.googleAnalytics || false, // Nouvelle sous-catégorie
        },
      },
      content: {
        enabled: preferences.content || false,
        subCategories: {
          awsAmazonContent: preferences.awsAmazonContent || false,
          giphyContent: preferences.giphyContent || false,
          googleMediaContent: preferences.googleMediaContent || false,
          contentX: preferences.contentX || false,
          youtubeContent: preferences.youtubeContent || false,
        },
      },
      productivity: {
        enabled: preferences.productivity || false,
        subCategories: {
          chiliPiper: preferences.chiliPiper || false, // Sous-catégorie Chili Piper
          googleProductivity: preferences.googleProductivity || false, // Sous-catégorie Google
        },
      },
    };


    console.log("Consentement structuré avec sous-catégories :", consent);

    // Sauvegarde dans le cookie
    document.cookie = `userConsent=${JSON.stringify(preferences)}; path=/; max-age=31536000`;
    console.log("Préférences sauvegardées dans le cookie.");

    // Mise à jour dans le localStorage
    localStorage.setItem("hasSeenCookiesConsent", "true");
    console.log("Consentement enregistré dans le localStorage.");

    // Sauvegarde dans le backend
    await savePreferencesToServer(preferences);
    console.log("Préférences sauvegardées dans le backend :", preferences);

     // Affichage d'un toast de confirmation
     toast({
      title: "Préférences sauvegardées.",
      description: "Vos préférences ont été enregistrées avec succès.",
      status: "success", // Statut : success
      duration: 3000, // Durée d'affichage en ms
      isClosable: true, // Bouton pour fermer le toast
      position: "top-right", // Position : 'top', 'top-right', etc.
    });

    // Fermeture de la modal
    setIsVisible(false);
    console.log("Modal de cookies fermée.");
  } catch (error) {
    console.error("Erreur lors de la sauvegarde des préférences :", error);

    // Affichage d'un toast d'erreur
    toast({
      title: "Erreur",
      description: "Une erreur est survenue lors de la sauvegarde des préférences.",
      status: "error", // Statut : error
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  }
};

  // Met à jour les préférences
  const handlePreferenceChange = (event) => {
    const { id, checked } = event.target;
  
    setPreferences((prev) => {
      // Mise à jour des sous-catégories si la catégorie principale est modifiée
      if (id === "advertising") {
        return {
          ...prev,
          advertising: checked,
          adobeMarketo: checked,
          googleAds: checked,
          linkedinMarketing: checked,
          adsX: checked,
        };
      }

      // Mise à jour des sous-catégories pour la catégorie "analytics"
    if (id === "analytics") {
      return {
        ...prev,
        analytics: checked,
        googleAnalytics: checked,
      };
    }

    // Mise à jour des sous-catégories pour la catégorie "content"
    if (id === "content") {
      return {
        ...prev,
        content: checked,
        awsAmazonContent: checked,
        giphyContent: checked,
        googleMediaContent: checked,
        contentX: checked,
        youtubeContent: checked,
      };
    }

     // Mise à jour des sous-catégories pour la catégorie "productivity"
     if (id === "productivity") {
      return {
        ...prev,
        productivity: checked,
        chiliPiper: checked,
        googleProductivity: checked,
      };
    }
  
      // Mise à jour d'une sous-catégorie
      const updatedPreferences = {
        ...prev,
        [id]: checked,
      };
  
      // Vérifier si toutes les sous-catégories sont activées
      const allSubCategoriesChecked =
        updatedPreferences.adobeMarketo &&
        updatedPreferences.googleAds &&
        updatedPreferences.linkedinMarketing &&
        updatedPreferences.adsX;

        // Vérifier si toutes les sous-catégories de "analytics" sont activées
       const allAnalyticsSubCategoriesChecked =
       updatedPreferences.googleAnalytics;


       // Vérifier si toutes les sous-catégories de "content" sont activées
       const allContentSubCategoriesChecked =
      updatedPreferences.awsAmazonContent &&
      updatedPreferences.giphyContent &&
      updatedPreferences.googleMediaContent &&
      updatedPreferences.contentX &&
      updatedPreferences.youtubeContent;

       // Vérifier si toutes les sous-catégories de "productivity" sont activées
      const allProductivitySubCategoriesChecked =
      updatedPreferences.chiliPiper &&
      updatedPreferences.googleProductivity;
  
      // Si toutes les sous-catégories sont activées, activer la catégorie principale
      if (id !== "advertising") {
        updatedPreferences.advertising = allSubCategoriesChecked;
      }

      // Si toutes les sous-catégories de "analytics" sont activées, activer la catégorie principale
    if (id !== "analytics") {
      updatedPreferences.analytics = allAnalyticsSubCategoriesChecked;
    }

    // Si toutes les sous-catégories de "content" sont activées, activer la catégorie principale
    if (id !== "content") {
      updatedPreferences.content = allContentSubCategoriesChecked;
    }

    // Si toutes les sous-catégories de "productivity" sont activées, activer la catégorie principale
    if (id !== "productivity") {
      updatedPreferences.productivity = allProductivitySubCategoriesChecked;
    }
  
      return updatedPreferences;
    });
  };


  // Fonction pour ouvrir l'overlay
  const handleOpenOverlay = (type) => {
    setOverlayType(type); // Définit le type d'overlay ('definition', 'why-cookies', etc.)
    setIsOverlayOpen(true);
  };

  // Fonction pour fermer l'overlay
  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  const handleOpenCategoryDetails = (category) => {
    console.log(`Ouverture de la catégorie : ${category}`); // Ajoutez un log pour déboguer
    setActiveCategory(category); // Définit la catégorie active (par ex. "advertising")
  };
  
  const handleCloseCategoryDetails = () => {
    console.log("Fermeture de la catégorie active."); // Ajoutez un log pour déboguer
    setActiveCategory(null); // Réinitialise la catégorie active
  };

  return isVisible ? (
    <>
      <div className="cookie-overlay"></div>
      <div className="cookie-modal">
      <h2 className="cookie-title">
      Autoriser l’utilisation des cookies <span style={{ display: 'block' }}>d’Onlybox</span> sur ce navigateur ?
        </h2>
      <p className="cookie-description">
       Nous utilisons des cookies et des technologies similaires pour optimiser et enrichir votre expérience sur Onlybox. Ces outils nous permettent également d’assurer une navigation plus sécurisée, grâce aux informations collectées sur et en dehors de notre plateforme, et d'améliorer continuellement nos services pour tous nos utilisateurs.
      </p>

      <ul className="cookie-list">
     <li>
    <strong>Cookies essentiels :</strong> ces cookies sont indispensables au bon fonctionnement de notre site et de ses fonctionnalités.
     </li>
    <li>
    <strong>Cookies marketing :</strong> utilisés pour afficher des publicités ciblées en fonction de vos centres d’intérêt.
    </li>
     <li>
    <strong>Cookies d’analyse :</strong> pour mesurer l’audience et comprendre comment les utilisateurs interagissent avec le site.
     </li>
    </ul>
    <p>
  Vous avez le contrôle sur les cookies optionnels que vous souhaitez conserver. Pour en savoir plus sur les cookies, leur utilisation, et pour revoir ou modifier vos préférences à tout moment, consultez notre{' '}
  <a
    href="/cookies-policy"
    onClick={(e) => {
      e.preventDefault(); // Empêche le comportement par défaut
      handleLinkClick(e); // Appelle la fonction pour fermer la modal et naviguer
    }}
  >
    Politique d’utilisation des cookies
  </a>.
</p>

     <h3 className="cookie-subtitle">À propos des cookies</h3> {/* Titre ajouté */}

        {/* Catégories avec boutons "En savoir plus" */}
        <div className="cookie-info-section">
  <div className="cookie-row">
    <div className="cookie-info">
      <span className="icon">😊</span>
      <h4>Définition des Cookies</h4>
            <button
              className="info-button"
              aria-label="En savoir plus sur la définition"
              onClick={() => handleOpenOverlay('definition')}
            >
              En savoir plus
            </button>
          </div>
          <div className="cookie-info">
            <span className="icon">🛡️</span>
            <h4>Pourquoi utiliser les cookies ?</h4>
            <button
              className="info-button"
              aria-label="En savoir plus sur pourquoi utiliser les cookies"
              onClick={() => handleOpenOverlay('why-cookies')}
            >
              En savoir plus
            </button>
          </div>
        </div>
        <div className="cookie-row">
          <div className="cookie-info">
            <span className="icon">∞</span>
            <h4>Qu’entendons-nous?</h4>
            <button
              className="info-button"
              aria-label="En savoir plus sur les produits Onlybox"
              onClick={() => handleOpenOverlay('what-we-mean')}
            >
              En savoir plus
            </button>
          </div>
          <div className="cookie-info">
            <span className="icon">⚙️</span>
            <h4>Vos préférences cookies</h4>
            <button
              className="info-button"
              aria-label="En savoir plus sur vos préférences cookies"
              onClick={() => handleOpenOverlay('preferences')}
            >
              En savoir plus
            </button>
          </div>
        </div>
      </div>

      {/* Insérez ici le bloc pour Cookies d’autres entreprises */}
      <div className="cookie-optional-section">
      <h3 className="cookie-optional-title">Cookies optionnels</h3> {/* Titre ajouté */}
     <div className="cookie-info">
     <h4 className="cookie-info-title">Cookies d’autres entreprises</h4>
     <p className="cookie-info-description">
    Nous utilisons les cookies d’autres entreprises pour vous montrer des publicités en dehors de nos Produits, et pour vous fournir des fonctionnalités comme les visualiseurs de cartes, les services de paiement et les lecteurs vidéo.
  </p>
  <label className="toggle-switch">
    <input
      type="checkbox"
      checked={preferences.thirdPartyCookies}
      onChange={() =>
        setPreferences((prev) => ({
          ...prev,
          thirdPartyCookies: !prev.thirdPartyCookies,
        }))
      }
    />
    <span className="slider"></span>
  </label>
  </div>
</div>

       {/* Panneau de personnalisation */}
       {showCustomization ? (
  <div className="customization-overlay">
    <div className="customization-container">
      <h3 className="customization-title">Choisissez les cookies</h3>
      <p className="customization-description">
        Nous utilisons les cookies des catégories d’entreprises suivantes pour diverses fonctionnalités, comme la personnalisation de vos publicités en dehors de nos Produits.
      </p>

      <div className="customization-categories">
        {/* Publicité */}
        <div className="category-item">
          <div className="cookie-info-title">
            <span>Publicité</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                id="advertising" // Utilise l'ID pour identifier la préférence
                checked={preferences.advertising || false}
                onChange={handlePreferenceChange} // Appelle handlePreferenceChange
              />
              <span className="slider"></span>
            </label>
          </div>
          <button className="view-details"
          onClick={() => handleOpenCategoryDetails('advertising')}
          >
            Voir tout</button>

        </div>

        {/* Analyse */}
        <div className="category-item">
          <div className="cookie-info-title">
            <span>Analyse</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                id="analytics" // Utilise l'ID pour identifier la préférence
                checked={preferences.analytics || false}
                onChange={handlePreferenceChange} // Appelle handlePreferenceChange
              />
              <span className="slider"></span>
            </label>
          </div>
          <button className="view-details"
          onClick={() => handleOpenCategoryDetails('analytics')}
          >
            Voir tout</button>

        </div>

        {/* Contenu multimédia */}
        <div className="category-item">
          <div className="cookie-info-title">
            <span>Contenu et contenu multimédia</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                id="content" // Utilise l'ID pour identifier la préférence
                checked={preferences.content || false}
                onChange={handlePreferenceChange} // Appelle handlePreferenceChange
              />
              <span className="slider"></span>
            </label>
          </div>
          <button className="view-details"
          onClick={() => handleOpenCategoryDetails('content')}
          >
            Voir tout</button>

        </div>
        
         {/* Productivité */}
         <div className="category-item">
          <div className="cookie-info-title">
            <span>Productivité</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                id="productivity"
                checked={preferences.productivity || false}
                onChange={handlePreferenceChange}
              />
              <span className="slider"></span>
            </label>
          </div>
          <button className="view-details"
          onClick={() => handleOpenCategoryDetails('productivity')}
          >
            Voir tout</button>

        </div>
      </div>
       {/* Boutons de sauvegarde/annulation */}
      <div className="customization-footer">
        <button
          className="save-button"
          onClick={() => {
            setShowCustomization(false);
            handleSavePreferences();
          }}
        >
          Sauvegarder
        </button>
        <button
          className="cancel-button"
          onClick={() => setShowCustomization(false)}
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
      
      ) : null}

{/* Overlay spécifique à la catégorie */}
{activeCategory === 'advertising' && (
  <div className="category-overlay">
    <div className="category-container">
      <h3>Publicité</h3>
      <div className="toggle-list">
        <div className="toggle-item">
          <span>Adobe Marketo</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="adobeMarketo"
              checked={preferences.adobeMarketo || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>Publicités Google</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="googleAds"
              checked={preferences.googleAds || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>Marketing LinkedIn</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="linkedinMarketing"
              checked={preferences.linkedinMarketing || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>Publicités X</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="adsX"
              checked={preferences.adsX || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      <button onClick={handleCloseCategoryDetails}>Fermer</button>
    </div>
  </div>
)}
        {/* Overlay spécifique à la catégorie Analyse */}
{activeCategory === 'analytics' && (
  <div className="category-overlay">
    <div className="category-container">
      <h3>Analyse</h3>
      <div className="toggle-list">
        <div className="toggle-item">
          <span>Google Analytics</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="googleAnalytics"
              checked={preferences.googleAnalytics || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      <button onClick={handleCloseCategoryDetails}>Fermer</button>
    </div>
  </div>
)}

{/* Overlay spécifique à la catégorie Contenu et contenu multimédia */}
{activeCategory === 'content' && (
  <div className="category-overlay">
    <div className="category-container">
      <h3>Contenu et contenu multimédia</h3>
      <div className="toggle-list">
        <div className="toggle-item">
          <span>Contenu AWS Amazon</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="awsAmazonContent"
              checked={preferences.awsAmazonContent || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>Giphy</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="giphyContent"
              checked={preferences.giphyContent || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>Contenu multimédia Google</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="googleMediaContent"
              checked={preferences.googleMediaContent || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>X</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="contentX"
              checked={preferences.contentX || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
        <div className="toggle-item">
          <span>YouTube</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="youtubeContent"
              checked={preferences.youtubeContent || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      <button onClick={handleCloseCategoryDetails}>Fermer</button>
    </div>
  </div>
)}

{/* Overlay spécifique à la catégorie Productivité */}
{activeCategory === 'productivity' && (
  <div className="category-overlay">
    <div className="category-container">
      <h3>Productivité</h3>
      <div className="toggle-list">
        {/* Chili Piper */}
        <div className="toggle-item">
          <span>Chili Piper</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="chiliPiper"
              checked={preferences.chiliPiper || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>

        {/* Google */}
        <div className="toggle-item">
          <span>Google</span>
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="googleProductivity"
              checked={preferences.googleProductivity || false}
              onChange={handlePreferenceChange}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      <button onClick={handleCloseCategoryDetails}>Fermer</button>
    </div>
  </div>
)}


        {/* Footer */}
        {!activeCategory && (
        <div className="cookie-footer">
          <button
            className="accept-all"
            onClick={handleAcceptAll}
            aria-label="Autoriser tous les cookies"
          >
            Autoriser tous les cookies
          </button>
          <button
            className="reject-all"
            onClick={handleRejectOptional}
            aria-label="Refuser les cookies optionnels"
          >
            Refuser les cookies optionnels
          </button>
          <button 
          className="customize-button"
          onClick={handleCustomize} 
          aria-label="Personnaliser les cookies"
          >
            Personnaliser
          </button>
        </div>
      )}
      </div>
   {/* Overlay */}
   {isOverlayOpen && overlayType === 'definition' && (
      <div className="cookie-overlay-content">
        <div className="cookie-overlay-container">
          <h3>Définition des Cookies</h3>
          <p>
            Les cookies sont de petits fichiers texte utilisés pour stocker et transmettre des identifiants via un navigateur web. Ils permettent d'offrir nos services et de mieux comprendre certaines informations liées aux utilisateurs, notamment leur activité sur d'autres sites et applications.
          </p>
          <p>
            Si vous n’avez pas de compte, nous n’utilisons pas les cookies pour personnaliser les publicités qui vous sont présentées. Les données collectées sont uniquement utilisées pour garantir la sécurité et l’intégrité de nos services.
          </p>
          <p>
           Pour en savoir plus sur l’utilisation des cookies et des technologies similaires, consultez notre{' '}
           <a
          href="/cookies-policy"
          onClick={(event) => {
          event.preventDefault(); // Empêche le comportement par défaut
          handleLinkClick(event); // Gère la fermeture de la modal et la navigation
          }}
           >
          Politique d’utilisation des cookies
           </a>.
          </p>
          <button className="close-overlay" onClick={handleCloseOverlay}>
           Fermer
          </button>
        </div>
      </div>
    )}
    {/* Overlay 2 : Pourquoi utiliser les cookies */}
    {isOverlayOpen && overlayType === 'why-cookies' && (
      <div className="cookie-overlay-content">
        <div className="cookie-overlay-container">
          <h3>Pourquoi utiliser les cookies ?</h3>
          <p>
            Les cookies jouent un rôle essentiel dans l’amélioration, la protection et l’optimisation de nos services. Ils permettent notamment de personnaliser les contenus que vous consultez, d’évaluer les publicités affichées et d’assurer une navigation sécurisée.
          </p>
          <p>
            Les cookies que nous utilisons peuvent évoluer au fil des améliorations apportées à nos services, mais ils servent principalement à :
          </p>
          <ul>
            <li>Éviter que vous n’ayez à vous reconnecter à chaque visite.</li>
            <li>Garantir la sécurité et l’intégrité de notre plateforme.</li>
            <li>
              Afficher des publicités, des recommandations, des statistiques et des analyses lorsque des annonces vous sont présentées.
            </li>
            <li>Activer certaines fonctionnalités et services essentiels.</li>
            <li>Évaluer la performance de nos outils et services.</li>
            <li>Mener des analyses pour innover et nous améliorer.</li>
            <li>
              Collecter des informations sur l’activité des sites Web et applications tiers utilisant nos technologies.
            </li>
          </ul>
          <p>
  Pour en savoir plus sur les cookies et leur utilisation, veuillez consulter notre{' '}
  <a
    href="/cookies-policy"
    onClick={(event) => {
      event.preventDefault(); // Empêche le rechargement immédiat de la page
      handleLinkClick(event); // Appelle la fonction pour gérer la navigation et la fermeture de la modal
    }}
  >
    Politique d’utilisation des cookies
     </a>.
     </p>
    <button className="close-overlay" onClick={handleCloseOverlay}>
  Fermer
  </button>
      </div>
      </div>
      )}

      {/* Overlay 3 : Qu’entendons-nous? */}
    {isOverlayOpen && overlayType === 'what-we-mean' && (
        <div className="cookie-overlay-content">
          <div className="cookie-overlay-container">
            <h3>Qu’entendons-nous ?</h3>
            <p>
        Onlybox désigne notre site web et les services associés que nous mettons à votre disposition. Cela inclut toutes les fonctionnalités, outils et solutions proposées sur notre plateforme pour améliorer votre expérience de navigation et répondre à vos besoins.
      </p>
      <p>
        Nos services englobent la personnalisation de votre contenu, des analyses de navigation, des recommandations pertinentes et des solutions adaptées à vos préférences. Ces fonctionnalités sont conçues pour rendre votre expérience utilisateur plus fluide, sécurisée et optimisée.
      </p>
      <p>
        Nous nous engageons également à respecter votre vie privée et à garantir que les données collectées via nos cookies sont utilisées uniquement dans le cadre des finalités décrites.
      </p>
      <p>
  Pour en savoir plus sur nos services et notre engagement en matière de confidentialité, veuillez consulter notre{' '}
  <a
    href="/privacy-policy"
    onClick={handlePrivacyLinkClick} // Gère la navigation vers privacy-policy
  >
    Politique de confidentialité
  </a>.
</p>
<button className="close-overlay" onClick={handleCloseOverlay}>
  Fermer
</button>
         </div>
        </div>
    )}
          {/* Overlay 4 : Vos préférences cookies */}
    {isOverlayOpen && overlayType === 'preferences' && (
        <div className="cookie-overlay-content">
          <div className="cookie-overlay-container">
            <h3>Vos préférences cookies</h3>
            <p>
        Vous avez la possibilité de sélectionner les cookies optionnels que vous souhaitez activer :
      </p>
      <p>
        Les cookies utilisés sur d'autres sites web ou applications collaborant avec Onlybox peuvent permettre de personnaliser votre expérience, notamment en affichant du contenu ou des recommandations adaptés.
      </p>
      <p>
        Les cookies tiers que nous utilisons servent également à afficher des publicités en dehors de notre site et à intégrer certaines fonctionnalités à Onlybox, comme des lecteurs vidéo ou des outils interactifs.
      </p>
      <p>
        Vous pouvez consulter ou ajuster vos préférences relatives aux cookies à tout moment via vos paramètres de cookies.
      </p>
      <button className="close-overlay" onClick={handleCloseOverlay}>
        Fermer
      </button>
    </div>
  </div>
)}
  </>
) : null;

};

export default CookieConsent;