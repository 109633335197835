import React, { useEffect } from 'react';
import { Box, Button, Text, VStack, Heading } from '@chakra-ui/react';

function AgeVerification() {
  

  // Vérifie si l'utilisateur a déjà confirmé son âge
  useEffect(() => {
    const isAdultConfirmed = localStorage.getItem('isAdultConfirmed');
    console.log('isAdultConfirmed depuis localStorage :', isAdultConfirmed);
    if (isAdultConfirmed === 'true') {
      console.log('Redirection en cours...');
      window.location.replace('/'); // Redirige si confirmé
    }
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;
 console.log("API URL:", apiUrl); // Vérification en console

  const handleEnter = () => {
    // Enregistre que l'utilisateur est majeur
    localStorage.setItem('isAdultConfirmed', 'true');
    console.log('Confirmation d\'âge enregistrée dans le localStorage.');
    window.location.replace('/'); // Redirige vers la page d'accueil sans boucle
  };

  const handleQuit = () => {
    window.location.href = 'https://www.google.com'; // Redirige vers Google en cas de refus
  };

  return (
    <Box textAlign="center" 
    padding="20px" 
    maxWidth="600px" 
    margin="auto"
    bg="black" // Fond noir
    color="white" // Texte blanc par défaut
    borderRadius="md"
    >
      <VStack spacing={4}>
         {/* Titre du site Blurimage avec le même style que dans HomePage */}
         <Heading
          sx={{
            background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            transition: 'transform 0.2s', // Effet de survol
            _hover: {
              transform: 'scale(1.1)', // Agrandit le texte au survol
            },
          }}
        >
          Onlybox
        </Heading>
        <Text fontSize="2xl" fontWeight="bold">
        Attention ⚠️
        </Text>
        <Text fontSize="md" color="gray.600" textAlign="justify" lineHeight="1.8" mt={4}>
       <Text as="span" fontWeight="bold" color="red.500">
        Le contenu de ce site n'est pas adapté à un public mineur.
       </Text>
       <br />
       En cliquant sur <Text as="span" fontWeight="bold" color="green.500">ENTRER</Text>, 
       je certifie que je suis majeur selon les lois en vigueur dans mon pays. 
       J'accepte également de recevoir des services et des offres adaptés.
       </Text>
        <Text fontSize="sm" color="gray.500" mt={4}>
      <Box as="section" mt={4}>
    <Text fontSize="md" fontWeight="bold" mb={2} color="gray.400">
      Article 227-22 du Code Pénal :
    </Text>
    <Text textAlign="justify" lineHeight="1.8">
      Conformément à l'Article 227-22 du Code pénal, toute personne ayant pour objectif de 
      promouvoir ou tentant de promouvoir la corruption d'un mineur s'expose à une sanction pénale 
      comprenant une peine d'emprisonnement de <strong>cinq (5) ans</strong> et une amende d'une 
      somme pouvant atteindre <strong>soixante-quinze mille (75 000) euros</strong>.
    </Text>
  </Box>

  <Box as="section" mt={4}>
    <Text fontSize="md" fontWeight="bold" mb={2} color="gray.400">
      Aggravation des peines :
    </Text>
    <Text textAlign="justify" lineHeight="1.8">
      Dans l'hypothèse où la corruption du mineur serait facilitée par l'utilisation d'un système 
      de communications électroniques à destination d'un public non-averti, ou encore si les faits 
      sont commis au sein d'un établissement scolaire, d'un établissement d'enseignement ou dans 
      les locaux relevant de l'administration publique, les peines encourues sont plus sévères.
    </Text>
    <Text textAlign="justify" lineHeight="1.8" mt={2}>
      En ce cas, la peine d'emprisonnement s'élève à <strong>sept (7) ans</strong>, et l'amende peut 
      atteindre <strong>cent mille (100 000) euros</strong>. Cette aggravation de la peine s'applique 
      également dans le cadre d'une commission des faits lors des moments d'entrée ou de sortie 
      des élèves dans ces établissements ou à proximité immédiate de ces locaux.
    </Text>
  </Box>

  <Box as="section" mt={4}>
    <Text fontSize="md" fontWeight="bold" mb={2} color="gray.400">
      Actes organisés et exhibitions sexuelles :
    </Text>
    <Text textAlign="justify" lineHeight="1.8">
      De même, la peine prévue par le présent article est identique (
      <strong>sept (7) ans d'emprisonnement</strong> et 
      <strong> 100 000 euros d'amende</strong>) lorsque les actes sont réalisés par un adulte dans 
      le cadre de l'organisation d'une réunion, impliquant des exhibitions ou des relations sexuelles 
      en présence de mineurs.
    </Text>
  </Box>

  <Box as="section" mt={4}>
    <Text fontSize="md" fontWeight="bold" mb={2} color="gray.400">
      Circonstances aggravantes :
    </Text>
    <Text textAlign="justify" lineHeight="1.8">
      Enfin, les peines peuvent atteindre des niveaux encore plus élevés en fonction de la gravité 
      des circonstances. En particulier, lorsque les faits sont perpétrés en bande organisée ou en 
      relation avec un mineur de moins de <strong>quinze (15) ans</strong>, les sanctions sont portées à une peine 
      d'emprisonnement de <strong>dix (10) ans</strong> et une amende d'<strong>un million 
      (1 000 000) d'euros</strong>.
    </Text>
  </Box>

  <Box as="section" mt={4}>
    <Text fontSize="md" fontWeight="bold" mb={2} color="gray.400">
      Objectifs de cet article :
    </Text>
    <Text textAlign="justify" lineHeight="1.8">
      Cet article vise à protéger les mineurs contre toute forme de corruption et à dissuader les 
      comportements criminels à leur égard, en renforçant les peines en fonction de la gravité 
      des circonstances et de l'environnement dans lequel les faits sont commis.
    </Text>
  </Box>
</Text>
        
        <VStack spacing={3} mt={5}>
          <Button colorScheme="green" onClick={handleEnter}>
            Je suis majeur - Entrer
          </Button>
          <Button colorScheme="red" onClick={handleQuit}>
            Quitter
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
}

export default AgeVerification;
